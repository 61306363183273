import { Container, Paper, Typography, TextField, Grid, Button, Alert } from "@mui/material";
import axios from "axios";
import { useState, useRef } from "react";
import BarChart from "./BarChart";
import "chartjs-plugin-datalabels";

const Calculator = ({ top }) => {
    const elementRef = useRef(null);
    const [userData, setUserData] = useState({});

    const [calcState, setCalcState] = useState({
        amount: 1000,
        months: 12,
        interestRate: 10
    })
    const [isCalc, setIsCalc] = useState(false)
    const [amountMessage, setAmountMessage] = useState("")
    const [monthsMessage, setMonthsMessage] = useState("")
    const [interestRateMessage, setInterestRateMessage] = useState("")

    const onChangeCalcAmount = (e) => {
        setCalcState(prevState => ({
            ...prevState,
            amount: e.target.value
        }))
    }

    const onChangeCalcMonths = (e) => {
        setCalcState(prevState => ({
            ...prevState,
            months: e.target.value
        }))
    }

    const onChangeCalcInterestRate = (e) => {
        setCalcState(prevState => ({
            ...prevState,
            interestRate: e.target.value
        }))
    }

    const handleCalculate = async () => {

        await axios.post(`/profit-calculator/calculate/`,
            {
                "amount": calcState.amount,
                "months": calcState.months,
                "interest_rate": calcState.interestRate
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if (res.data.response) {
                const chartData = res.data.response
                setTimeout(() => {
                    elementRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 200)
                setIsCalc(true)
                setUserData(
                    {
                        labels: chartData.map((data, i) => {
                            const words = data.label.split(" ")
                            const wordPairs = [];
                            for (let i = 0; i < words.length - 1; i++) {
                                wordPairs.push(words[i] + " " + words[i + 1]);
                            }
                            return wordPairs
                        }),
                        datasets: [
                            {
                                data: chartData.map((data) => data.value),
                                backgroundColor: [
                                    chartData[0].color,
                                    chartData[1].color,
                                    chartData[2].color,
                                    chartData[3].color,
                                    chartData[4].color,
                                    chartData[5].color,
                                ]
                            },
                        ],
                    }
                )

            }
        }).catch(err => {
            setIsCalc(false)
            Object.keys(err.response.data).map(key => {
                switch (key) {
                    case "amount":
                        const amount = err.response.data.amount[0]
                        return setAmountMessage(amount)
                    case "months":
                        const months = err.response.data.months[0]
                        return setMonthsMessage(months)
                    case "interest_rate":
                        const interestRate = err.response.data.interest_rate[0]
                        return setInterestRateMessage(interestRate)
                    default:
                        return setAmountMessage(null)
                }
            })
        })
    }

    return (
        <Container maxWidth="lg" style={{ marginTop: top, padding: "0", maxWidth: "900px" }}>
            <Typography component="h1" variant="h3" color={"primary"} textAlign={"center"} marginBottom={5} fontSize={72}>Kalkulator Zysku</Typography>
            <Paper sx={{ padding: "20px", boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.5)', marginBottom: "30px" }}>
                <Typography component="h2" variant="h4" color={"rgba(0, 0, 0, 0.5)"} textAlign={"center"} fontSize={30} marginY={5}>Oblicz wartość inwestycji</Typography>
                <Grid container spacing={1} marginBottom={1} px={5}>
                    <Grid item xs={3} >
                        <Typography textAlign={"center"} fontSize={14}>Wartość Inwestycji</Typography>
                        <TextField
                            type="number"
                            value={calcState.amount}
                            onChange={onChangeCalcAmount}
                        />
                        {
                            amountMessage && <Alert sx={{ mb: 4 }} severity="error" style={{ fontSize: "10px" }}> {amountMessage}</Alert>
                        }
                    </Grid>
                    <Grid item xs={3} >
                        <Typography textAlign={"center"} fontSize={14}>Okres Inwestycji</Typography>
                        <TextField
                            type="number"
                            value={calcState.months}
                            onChange={onChangeCalcMonths}
                        />
                        {
                            monthsMessage && <Alert sx={{ mb: 4 }} severity="error" style={{ fontSize: "10px" }}> {monthsMessage}</Alert>
                        }
                    </Grid>
                    <Grid item xs={3} >
                        <Typography textAlign={"center"} fontSize={14}>Oprocentowanie</Typography>
                        <TextField
                            type="number"
                            value={calcState.interestRate}
                            onChange={onChangeCalcInterestRate}
                        />
                        {
                            interestRateMessage && <Alert sx={{ mb: 4 }} severity="error" style={{ fontSize: "10px" }}> {interestRateMessage}</Alert>
                        }
                    </Grid>
                    <Grid item xs={3} >
                        <Button type="submit" fullWidth color='secondary' variant="contained" onClick={handleCalculate} sx={{ cursor: "pointer", marginTop: "22px", padding: "16.5px 5px", }}>Sprawdź Zysk*</Button>
                    </Grid>
                </Grid>
                <Typography marginTop={5} marginLeft={5} marginBottom={2}>*Zyski brutto tj. przed podatkiem od zysków kapitałowych (tzw. podatek Belki)</Typography>
            </Paper>
            <div ref={elementRef}>
                {
                    isCalc
                    &&
                    <Paper sx={{ padding: "20px", boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.5)', marginBottom: "30px" }}>
                        <Typography component="h1" variant="h3" color={"rgba(0, 0, 0, 0.5)"} textAlign={"center"} marginY={5} fontSize={30}>Zobacz ile zarobisz (w zł)</Typography>
                        <BarChart
                            chartData={userData}
                            options={{
                                animation: {
                                    delay: 800
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    datalabels: {
                                        color: userData.datasets[0].backgroundColor.map(el => el),
                                        anchor: 'end',
                                        align: 'top',
                                        font: {
                                            size: "14px",
                                            weight: "700"
                                        },
                                        formatter: (value, context) => {
                                            return context.chart.data.datasets[0].data[context.dataIndex] + " zł"
                                        },
                                    },
                                    tooltip: {
                                        callbacks: {
                                            title: context => {
                                                return context[0].label.replaceAll(",", " ")
                                            },

                                        }
                                    }
                                },
                                scales: {
                                    x: {
                                        offset: true,
                                        grid: {
                                            offset: true
                                        },
                                        ticks: {
                                            font: {
                                                size: 10,
                                                weight: 600
                                            }
                                        }
                                    },
                                    y: {
                                        offset: true,
                                    }
                                }
                            }} />
                    </Paper>
                }
            </div>
        </Container>
    )
}

export default Calculator;