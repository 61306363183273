import { useEffect, useState } from "react";
import InvestmentCard from "./InvestmentCard";
import { CircularProgress, Grid, } from '@mui/material';
import axios from "axios";
import InvestmentLoader from "./InvestmentLoader";
import CrowderTabPanelInvestments from "../CrowderTabPanelInvestments";

const Investments = () => {

    const [loadingProjects, setLoadingProjects] = useState(true)
    const [loadingCounts, setLoadingCounts] = useState(true)
    const [countsPending, setCountsPending] = useState(0)
    const [countsActual, setCountsActual] = useState(0)
    const [countsSuccess, setCountsSuccess] = useState(0)
    const [projectsPending, setProjectsPending] = useState([])
    const [projectsActual, setProjectsActual] = useState([])
    const [projectsSuccess, setProjectsSuccess] = useState([])
    const [projectName, setProjectName] = useState('');
    const [product, setProduct] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [filterByPeriod, setFilterByPeriod] = useState('')
    const TabsEl = [{
        id: 0,
        pl: <div>Nadchodzące ({loadingCounts ? <CircularProgress size={15} /> : countsPending})</div>,
        Content: <>
            {
                loadingProjects
                    ?
                    <InvestmentLoader />
                    :
                    <Grid container style={{ justifyContent: "flex-start" }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {
                            projectsPending !== undefined &&
                            projectsPending.map((el, i) => {
                                return (
                                    <Grid key={i} item>
                                        <InvestmentCard
                                            id={el.id}
                                            key={i}
                                            productType={el.productType}
                                            title={el.title}
                                            location={el.location}
                                            CardImg={el.CardImg}
                                            scoringRate={el.scoringRate}
                                            investedAmount={el.investedAmount}
                                            requiredAmount={el.requiredAmount}
                                            investedPercentage={el.investedPercentage}
                                            annualYield={el.annualYield}
                                            investorsNo={el.investorsNo}
                                            totalYield={el.totalYield}
                                            loanStartDate={el.loanStartDate}
                                            loanPaidDate={el.loanPaidDate}
                                            variableInterestVisible={el.variableInterestVisible}
                                            status={el.status}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
            }
        </>
    },
    {
        id: 1,
        pl: <div>Trwające ({loadingCounts ? <CircularProgress size={15} /> : countsActual})</div>,
        Content: <>
            {
                loadingProjects
                    ?
                    <InvestmentLoader />
                    :
                    <Grid container style={{ justifyContent: "flex-start" }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {
                            projectsActual !== undefined &&
                            projectsActual.map((el, i) => {
                                return (
                                    <Grid key={i} item>
                                        <InvestmentCard
                                            id={el.id}
                                            key={i}
                                            productType={el.productType}
                                            title={el.title}
                                            location={el.location}
                                            CardImg={el.CardImg}
                                            scoringRate={el.scoringRate}
                                            investedAmount={el.investedAmount}
                                            requiredAmount={el.requiredAmount}
                                            investedPercentage={el.investedPercentage}
                                            annualYield={el.annualYield}
                                            investorsNo={el.investorsNo}
                                            totalYield={el.totalYield}
                                            loanStartDate={el.loanStartDate}
                                            loanPaidDate={el.loanPaidDate}
                                            variableInterestVisible={el.variableInterestVisible}
                                            status={el.status}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
            }
        </>
    },
    {
        id: 2,
        pl: <div>Sfinansowane ({loadingCounts ? <CircularProgress size={15} /> : countsSuccess})</div>,
        Content: <>
            {
                loadingProjects
                    ?
                    <InvestmentLoader />
                    :
                    <Grid container style={{ justifyContent: "flex-start" }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {
                            projectsSuccess !== undefined &&
                            projectsSuccess.map((el, i) => {
                                return (
                                    <Grid key={i} item>
                                        <InvestmentCard
                                            id={el.id}
                                            key={i}
                                            productType={el.productType}
                                            title={el.title}
                                            location={el.location}
                                            CardImg={el.CardImg}
                                            scoringRate={el.scoringRate}
                                            investedAmount={el.investedAmount}
                                            requiredAmount={el.requiredAmount}
                                            investedPercentage={el.investedPercentage}
                                            annualYield={el.annualYield}
                                            investorsNo={el.investorsNo}
                                            totalYield={el.totalYield}
                                            loanStartDate={el.loanStartDate}
                                            loanPaidDate={el.loanPaidDate}
                                            variableInterestVisible={el.variableInterestVisible}
                                            status={el.status}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
            }
        </>
    }
    ]
    const [tabValue, setTabValue] = useState(1);

    const handleFilterByPeriodByChange = (e) => {
        setFilterByPeriod(e.target.value);
    }

    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
    };

    const handleProductChange = (e) => {
        setProduct(e.target.value);
    };

    const handleSortByChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleFilterSubmit = () => {
        if (localStorage.getItem('refresh') !== '' && localStorage.getItem('refresh') !== null) {
            getRefresh()
        }
        switch (tabValue) {
            case 0:
                switch (filterByPeriod) {
                    case "6":
                        getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, null, null, null, 6, tabValue)
                        break
                    case "6and12":
                        getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, 6, null, null, 12, tabValue)
                        break
                    case "12and24":
                        getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, 12, null, null, 24, tabValue)
                        break
                    case "24":
                        getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, 24, null, null, null, tabValue)
                        break
                    default:
                        getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, null, null, null, null, tabValue)
                }
                break
            case 1:
                switch (filterByPeriod) {
                    case "6":
                        getProjectsFilterAndSort(projectName, product, null, "open", sortBy, null, null, null, 6, tabValue)
                        break
                    case "6and12":
                        getProjectsFilterAndSort(projectName, product, null, "open", sortBy, 6, null, null, 12, tabValue)
                        break
                    case "12and24":
                        getProjectsFilterAndSort(projectName, product, null, "open", sortBy, 12, null, null, 24, tabValue)
                        break
                    case "24":
                        getProjectsFilterAndSort(projectName, product, null, "open", sortBy, 24, null, null, null, tabValue)
                        break
                    default:
                        getProjectsFilterAndSort(projectName, product, null, "open", sortBy, null, null, null, null, tabValue)
                }
                break
            case 2:
                switch (filterByPeriod) {
                    case "6":
                        getProjectsFilterAndSort(projectName, product, null, "success", sortBy, null, null, null, 6, tabValue)
                        break
                    case "6and12":
                        getProjectsFilterAndSort(projectName, product, null, "success", sortBy, 6, null, null, 12, tabValue)
                        break
                    case "12and24":
                        getProjectsFilterAndSort(projectName, product, null, "success", sortBy, 12, null, null, 24, tabValue)
                        break
                    case "24":
                        getProjectsFilterAndSort(projectName, product, null, "success", sortBy, 24, null, null, null, tabValue)
                        break
                    default:
                        getProjectsFilterAndSort(projectName, product, null, "success", sortBy, null, null, null, null, tabValue)
                }
                break
            default:
                getProjectsFilterAndSort(projectName, product, null, "open", sortBy, null, null, null, null, tabValue)
        }
    };

    const handleChange = (event, newValue) => {
        getProjectsCount()
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                PromisePendingProjects()
                break
            case 1:
                PromiseActualProjects()
                break
            case 2:
                PromiseSuccessProjects()
                break
            default:
                PromiseActualProjects()
        }
    };

    const getRefresh = async () => {
        axios.post('/token/refresh/',
            {
                "refresh": localStorage.getItem("refresh"),
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(userData => {
            localStorage.setItem("token", userData.data.access)
        }).catch(err => {
            console.error(err)
        })
    }

    const setProjectsType = (projectsType, pendingArr) => {
        switch (projectsType) {
            case 0:
                setProjectsPending(pendingArr)
                break
            case 1:
                setProjectsActual(pendingArr)
                break
            case 2:
                setProjectsSuccess(pendingArr)
                break
            default:
                setProjectsActual(pendingArr)
        }
    }
    const getProjectsFilterAndSort = async (
        filterBy,
        productType,
        investmentPeriod,
        status,
        sortBy,
        investmentPeriod__gt,
        investmentPeriod__gte,
        investmentPeriod__lt,
        investmentPeriod__lte,
        projectsMode,
    ) => {

        return await axios.get("/projects/", {
            params: {
                "name__icontains": filterBy,
                "product_type": productType,
                "investment_period": investmentPeriod,
                "status": status,
                "ordering": sortBy,
                "investment_period__gt": investmentPeriod__gt,
                "investment_period__gte": investmentPeriod__gte,
                "investment_period__lt": investmentPeriod__lt,
                "investment_period__lte": investmentPeriod__lte,
            }
        })
            .then((res) => {
                const data = res.data.results
                let pendingArr = []
                data.forEach((el, i) => {
                    pendingArr.push(
                        {
                            id: el.id,
                            productType: el.product_type,
                            title: el.name,
                            location: el.address,
                            scoringRate: el.scoring_rate,
                            CardImg: el.picture !== null && el.picture ? el.picture.original : null,
                            investedAmount: el.invested_amount,
                            requiredAmount: el.required_amount,
                            investedPercentage: el.invested_percentage,
                            annualYield: el.annual_yield,
                            investorsNo: el.investors_no,
                            totalYield: el.total_yield,
                            loanPaidDate: el.loan_paid_date,
                            loanStartDate: el.loan_start_date,
                            variableInterestVisible: el.variable_interest_visible,
                            status: el.status,
                        })
                })
                setProjectsType(projectsMode, pendingArr)
                pendingArr.length > 2 && setLoadingProjects(false)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const PromisePendingProjects = async () => {
        try {
            let promises = []
            if (localStorage.getItem('refresh') !== '' && localStorage.getItem('refresh') !== null) {
                promises.push(getRefresh())
                promises.push(getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, null, null, null, null, 0))
                await Promise.all(promises)
            } else {
                getProjectsFilterAndSort(projectName, product, null, "pending_verification", sortBy, null, null, null, null, 0)
            }
        } catch (err) {
            console.error("errPromise: ", err)
        }
    }

    const PromiseActualProjects = async () => {
        try {
            let promises = []

            if (localStorage.getItem('refresh') !== '' && localStorage.getItem('refresh') !== null) {
                promises.push(getRefresh())
                promises.push(getProjectsFilterAndSort(projectName, product, null, "open", sortBy, null, null, null, null, 1))
                await Promise.all(promises)
            } else {
                await getProjectsFilterAndSort(projectName, product, null, "open", sortBy, null, null, null, null, 1)
            }
        } catch (err) {
            console.error("errPromise: ", err)
        }
    }

    const PromiseSuccessProjects = async () => {
        try {
            let promises = []

            if (localStorage.getItem('refresh') !== '' && localStorage.getItem('refresh') !== null) {
                promises.push(getRefresh())
                promises.push(getProjectsFilterAndSort(projectName, product, null, "success", sortBy, null, null, null, null, 2))
                await Promise.all(promises)
            } else {
                getProjectsFilterAndSort(projectName, product, null, "success", sortBy, null, null, null, null, 2)
            }
        } catch (err) {
            console.error("errPromise: ", err)
        }
    }

    const getProjectsCount = async () => {
        axios.get("/projects/counts/public/")
            .then(res => {
                setCountsPending(res.data.pending_verification)
                setCountsActual(res.data.open)
                setCountsSuccess(res.data.success)
                setLoadingCounts(false)
                res.data && setLoadingProjects(false)
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        handleChange(null, 1)
        PromisePendingProjects()
        PromiseSuccessProjects()
        getProjectsCount()
    }, [])

    return (
        <CrowderTabPanelInvestments
            handleFilterByPeriodByChange={handleFilterByPeriodByChange}
            handleProductChange={handleProductChange}
            handleProjectNameChange={handleProjectNameChange}
            handleSortByChange={handleSortByChange}
            handleFilterSubmit={handleFilterSubmit}
            filterByPeriod={filterByPeriod}
            TabsEl={TabsEl}
            handleChange={handleChange}
            tabValue={tabValue}
        />
    );
}

export default Investments