import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomArrow from './CustomArrow/index.';
import defaultImage from '../../assets/img/cardimg.jpg';

const SliderShow = ({ images }) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <CustomArrow isArrowRight={false} />,
        nextArrow: <CustomArrow isArrowRight={true} />,
    };

    return (
        <Slider {...settings} style={{ marginBottom: "30px" }}>
            {
                images.length !== 0
                    ?
                    images.map((image, index) => (
                        <div key={index} >
                            <img style={{ width: "100%" }} src={image} alt={`Slide ${index}`} />
                        </div>
                    ))
                    :
                    <div key={0}>
                        <img style={{ width: "100%" }} src={defaultImage} alt={`Slide ${0}`} />
                    </div>
            }
        </Slider>
    );
}

export default SliderShow;