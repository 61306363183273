import { useState, useRef, Fragment } from "react";
import axios from "axios";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from "@mui/material/Button";
import { ValidatorForm } from 'react-material-ui-form-validator';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert, Container } from "@mui/material";
import bgImage from "../../assets/img/bg-sign-up.png";
import Logo from "../../assets/img/logo_new.png";
import { useNavigate, } from "react-router-dom";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import uuid from "react-uuid";

const steps = ['Dane do konta Crowder.Pro', 'Dane osobowe'];

const Register = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const streetRef = useRef();
    const postCodeRef = useRef();
    const cityRef = useRef();
    const consentToTermsRef = useRef();
    const consentToRodoRef = useRef();
    const consentToMarketingRef = useRef();
    const consentToTelemarketingRef = useRef();

    const refs = {
        emailRef: emailRef,
        passwordConfRef: passwordConfRef,
        passwordRef: passwordRef,
        consentToTermsRef: consentToTermsRef,
        consentToRodoRef: consentToRodoRef,
        consentToMarketingRef: consentToMarketingRef,
        consentToTelemarketingRef: consentToTelemarketingRef
    }

    const personalRefs = {
        nameRef: nameRef,
        phoneRef: phoneRef,
        streetRef: streetRef,
        postCodeRef: postCodeRef,
        cityRef: cityRef,
    }

    const [validatorCustomTerm, setValidatorCustomTerm] = useState(false);
    const [validatorCustomRodo, setValidatorCustomRodo] = useState(false);
    const [registerTitle, setRegisterTitle] = useState("Rejestracja");
    const [finalMessage, setFinalMessage] = useState("Reset")
    const [registerMessage, setRegisterMessage] = useState("")
    const [regMesState, setRegMesState] = useState('error')
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [userState, setUserState] = useState({
        user: {
            email: "",
            password: "",
            name: "",
            phone: "",
            street: "",
            postcode: "",
            city: "",
            consent_to_terms: false,
            consent_to_rodo: false,
            consent_to_marketing: false,
            consent_to_telemarketing: false,
        },
        passwordConfirm: '',
    })

    const navigate = useNavigate()

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ?
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setRegisterTitle("Dane osobowe")
        handleNext();
    };

    const handleReset = () => {
        if (registerMessage === "Rejestracja przebiegła pomyślnie") {
            navigate("/login")
        } else {
            setActiveStep(0);
            setCompleted({});
        }
    };

    const handleValidate = () => {

        ValidatorForm.addValidationRule('isEmailMatch', (value) => {
            if (value && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isPasswordLongEnough', (value) => {
            if (value.length < 8) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isPasswordSpecial', (value) => {
            if (value && value.match(/^(?=.*[!@#$%^&*()_+{}\]:;<>,.?~\\|/\\-])(?=.*\d).+$/)) {
                return true;
            }
            return false;
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const formData = userState.user;
            if (value !== formData.password) {
                return false;
            }
            return true;
        });
        // ValidatorForm.addValidationRule('isTermsChecked', () => {
        setValidatorCustomTerm(consentToTermsRef.current?.checked);
        setValidatorCustomRodo(consentToRodoRef.current?.checked);
        // })

        ValidatorForm.addValidationRule('isNameAndSurname', (value) => {

            if (value && value.match(/\s/g)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isPhone', (value) => {

            if (value && value.match(/^\+?1?\d{9,15}$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isStreet', (value) => {

            if (value && value.match(/^[A-ZĄĆĘŁŃÓŚŹŻa-ząćęłńóśźż\s\d.,/-]+$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isPostCode', (value) => {
            if (value && value.match(/^\d{2}-\d{3}$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isEmpty', (value) => {

            if (value && !value.match(/^\s*$/)) {
                return true;
            }
            return false;
        });
    }

    const setChange = () => {

        setUserState({
            ...userState,
            user: {
                ...userState.user,
                email: emailRef.current?.value,
                password: passwordRef.current?.value,
                name: nameRef.current?.value,
                phone: phoneRef.current?.value,
                street: streetRef.current?.value,
                consent_to_terms: consentToTermsRef.current?.checked,
                consent_to_rodo: consentToRodoRef.current?.checked,
                consent_to_marketing: consentToMarketingRef.current?.checked,
                consent_to_telemarketing: consentToTelemarketingRef.current?.checked,
            },
            passwordConfirm: passwordConfRef.current?.value
        });
        handleValidate()
    }

    const onchange = () => {
        setUserState({
            ...userState,
            user: {
                ...userState.user,
                name: nameRef.current?.value,
                phone: phoneRef.current?.value,
                street: streetRef.current?.value,
                postcode: postCodeRef.current?.value,
                city: cityRef.current?.value,
            },
        });
        handleValidate()
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        if ((validatorCustomTerm && validatorCustomRodo) || activeStep === 1) {
            handleComplete();
        }
        if (activeStep === 1) {
            axios.post('/users/register/',
                {
                    "email": userState.user.email,
                    "password": userState.user.password,
                    "name": userState.user.name,
                    "phone_number": userState.user.phone,
                    "street": userState.user.street,
                    "postcode": userState.user.postcode,
                    "city": userState.user.city,
                    "bonus_code": "",
                    "consent_to_terms": userState.user.consent_to_terms,
                    "consent_to_rodo": userState.user.consent_to_rodo,
                    "consent_to_marketing": userState.user.consent_to_marketing,
                    "consent_to_telemarketing": userState.user.consent_to_telemarketing
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(res => {
                setRegisterMessage("Rejestracja przebiegła pomyślnie");
                setRegMesState("success")
                setFinalMessage("Zaloguj się")
                setTimeout(() => {
                    navigate(`/register-email-verify/?access=${res.data.access}`)
                }, 2000)
            }
            ).catch(err => {
                console.log("error: ", err)
                setRegMesState("error")
                setRegisterMessage(err.response.data)
            });
        }
        handleValidate()
    };

    const renderStep = (step) => {
        switch (step) {
            case 0:
                return (
                    <FirstStep
                        setChange={setChange}
                        userState={userState}
                        validatorCustomTerm={validatorCustomTerm}
                        validatorCustomRodo={validatorCustomRodo}
                        ref={refs}
                    />
                )
            case 1:
                return (
                    <SecondStep
                        setChange={onchange}
                        userState={userState}
                        ref={personalRefs}
                    />
                )
            default:
                return (
                    <FirstStep
                        setChange={setChange}
                        userState={userState}
                        validatorCustomTerm={validatorCustomTerm}
                        validatorCustomRodo={validatorCustomRodo}
                        ref={refs}
                    />
                )
        }
    }

    return (
        <Container
            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                WebkitBackgroundSize: "cover",
                minHeight: "100vh",
                width: "100%",
                maxWidth: "none",
            }}
            style={{
                maxWidth: "none",
                padding: "0px"
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    sx={{ background: "#ffffff", width: "100%", minHeight: "100vh" }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            height: "70px",
                            margin: "48px 32px"
                        }}
                    >
                        <Box
                            component="img"
                            alt="Crowder"
                            src={Logo}
                        />
                    </Box>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "left",
                        }}
                    >
                        <Typography component="h1" variant="h3" fontSize={31} sx={{ marginBottom: "10px" }}>
                            {registerTitle}
                        </Typography>
                        <Typography component="p" fontSize={14} sx={{ marginBottom: "10px" }}>
                            Stwórz konto na platformie Crowder.pro w 2 prostych krokach
                        </Typography>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton key={uuid()} color="inherit" style={{ cursor: "auto" }}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <ValidatorForm
                            noValidate
                            onSubmit={handleSubmit}
                            onError={errors => console.log(errors)}
                        >
                            <Box
                                sx={{ my: 4 }}
                            >

                                {
                                    renderStep(activeStep)
                                }

                            </Box>
                            {allStepsCompleted() ? (
                                <Fragment>
                                    {typeof registerMessage === 'string'
                                        ?
                                        <Alert severity={regMesState}>
                                            <Typography sx={{ mt: 2, mb: 1 }}>{registerMessage}</Typography>
                                        </Alert>
                                        :
                                        Object.values(registerMessage).map((el) => {
                                            return (
                                                <Alert severity={regMesState}>
                                                    <Typography sx={{ mt: 2, mb: 1 }}>{el}</Typography>
                                                </Alert>
                                            )
                                        })}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button
                                            onClick={handleReset}
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            {finalMessage}
                                        </Button>
                                    </Box>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {activeStep !== steps.length &&
                                            ((
                                                completedSteps() === totalSteps() - 1
                                                    ?
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Zarejestruj
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Dalej
                                                    </Button>
                                            ))
                                        }
                                    </Box>
                                </Fragment>
                            )}
                        </ValidatorForm>
                    </Box>
                </Grid>
            </Grid>
        </Container >
    );
}

export default Register;