import { Box, Typography } from '@mui/material';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from "leaflet";
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

const InvestmentDescription = ({ id }) => {
    const [descriptionOffer, setDescriptionOffer] = useState('')
    const [reason, setReason] = useState('')
    const [localizationDesc, setLocalizationDesc] = useState('')
    const [projectDesc, setProjectDesc] = useState('')
    const [saves, setSaves] = useState('')
    const [documentation, setDocumentation] = useState('')
    const [projectDesignerDesc, setProjectDesignerDesc] = useState('')
    const [location, setLocation] = useState([])


    const customIcon = new Icon({
        iconUrl: require("../../../../../assets/img/marker-icon.png"),
        iconSize: [25.5, 37.75]
    })

    useEffect(() => {
        axios.get(`/projects/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            console.log("opis: ", res)
            setDescriptionOffer(res.data.descriptions[0].content)
            setReason(res.data.descriptions[1].content)
            setLocalizationDesc(res.data.descriptions[2].content)
            setProjectDesc(res.data.descriptions[3].content)
            setProjectDesignerDesc(res.data.descriptions[4].content)
            setSaves()
            setLocation(res.data.location)
        })
            .catch((err) => {
                console.error("error opis:", err)
            })
    }, [])

    return (
        <Box>
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Oferta Pożyczkowa</Typography>
            <div dangerouslySetInnerHTML={{ __html: descriptionOffer }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Powody by zainwestować w dziesiaty</Typography>
            <div dangerouslySetInnerHTML={{ __html: reason }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Lokalizacja</Typography>
            <div dangerouslySetInnerHTML={{ __html: localizationDesc }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Projekt</Typography>
            <div dangerouslySetInnerHTML={{ __html: projectDesc }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Twórca projektu</Typography>
            <div dangerouslySetInnerHTML={{ __html: projectDesignerDesc }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Dokumentacja</Typography>
            <div dangerouslySetInnerHTML={{ __html: documentation }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={24} fontWeight={400} py={2}> Zabezpieczenia</Typography>
            <div dangerouslySetInnerHTML={{ __html: saves }} style={{ color: '#67757c', fontFamily: "Roboto" }} />
            <MapContainer center={[52.229675, 21.012230]} zoom={13} scrollWheelZoom={true} style={{ width: '100%', height: '400px' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[52.229675, 21.012230]} icon={customIcon} />
            </MapContainer>
        </Box>
    )
}

export default InvestmentDescription