import { Paper, Box, Typography, } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';

const VerticalTimeline = ({ loanPaidDate, loanStartDate }) => {

    return (
        <Box sx={{ background: "#fff", padding: "20px", }}>
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.01,
                    },
                }}
            >
                <TimelineItem >
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper sx={{ padding: "20px" }}>
                            <Typography fontSize={12}>{loanStartDate}</Typography>
                            <Typography color={"rgba(0, 0, 0, 0.5)"} variant="h6" fontSize={14}>Inwestycja rozpoczęta</Typography>
                            <Typography fontSize={10}>Rozpoczęto zbierać fundusze</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem >
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper sx={{ padding: "20px" }}>
                            <Typography fontSize={12}>{loanPaidDate}</Typography>
                            <Typography color={"rgba(0, 0, 0, 0.5)"} variant="h6" fontSize={14}>Inwestycja zakończona</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Box>
    );
}

export default VerticalTimeline;  