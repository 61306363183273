import PropTypes from 'prop-types';
import { Box, Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import EditUser from "./EditUser";
import uuid from "react-uuid";
import Verification from './Verification';
import PaymentData from './PaymentData';

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const CustomTab = styled(Tab)(({ theme, selected }) => ({
    backgroundColor: selected ? '#fff' : 'inherit',
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Profile = () => {

    const { tab } = useParams()

    const [tabValue, setTabValue] = useState(0);

    const VisuallyHiddenInput = styled('input')({
        display: "none"
    });

    const TabsEl = [{
        id: 0,
        pl: <Typography fontSize={18} marginBottom={1}>Edycja profilu</Typography>,
        Content:

            <EditUser
                VisuallyHiddenInput={VisuallyHiddenInput}
            />
    },
    {
        id: 1,
        pl: <Typography fontSize={18} marginBottom={1}>Moje bonusy</Typography>,
        Content: ""
    },
    {
        id: 2,
        pl: <Typography fontSize={18} marginBottom={1}>Weryfikacja</Typography>,
        Content: <Verification />
    },
    {
        id: 3,
        pl: <Typography fontSize={18} marginBottom={1}>Dane do wypłat</Typography>,
        Content: <PaymentData />
    },
    ]

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    useEffect(() => {
        if (tab === "profile-verify") {
            handleChange(null, 2)
        }
    }, [])

    return (
        <Container maxWidth={'lg'} sx={{ marginTop: "100px", marginBottom: "20px" }}>
            <Paper>
                <Box padding={2}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            variant="fullWidth"
                            value={tabValue}
                            onChange={handleChange}
                        >
                            {
                                TabsEl.map((tabel, i) => {
                                    return (
                                        <CustomTab key={uuid()} label={tabel.pl} {...a11yProps(i)} />
                                    )
                                })
                            }
                        </Tabs>
                        {TabsEl.map((tabel, i) => {
                            return (
                                <CustomTabPanel key={uuid()} value={tabValue} index={tabel.id}>
                                    {tabel.Content}
                                </CustomTabPanel>
                            )
                        })

                        }
                    </Box>
                </Box>
            </Paper>
        </Container>
    )
}

export default Profile