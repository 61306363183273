import { Alert, Box, Button, FormHelperText, Grid, Snackbar } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import axios from 'axios'

const PaymentData = () => {

    const nameAndSurnameRef = useRef()
    const BankAccountNumberRef = useRef()
    const streetRef = useRef()
    const postCodeRef = useRef()
    const cityRef = useRef()
    const BICSWIFTRef = useRef()

    const [alertState, setAlertState] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = openSnackbar;
    const [snackMessage, setSnackMessage] = useState("")
    const [isPostCode, setIsPostCode] = useState(false)

    const [userState, setUserState] = useState({
        user: {
            nameAndSurname: "",
            street: "",
            city: "",
            postcode: "",
            BankAccountNumber: "",
            BICSWIFT: ""
        }
    })

    const setChange = () => {

        setUserState({
            ...userState,
            user: {
                ...userState.user,
                nameAndSurname: nameAndSurnameRef.current?.value,
                street: streetRef.current?.value,
                city: cityRef.current?.value,
                postcode: postCodeRef.current?.value,
                BankAccountNumber: BankAccountNumberRef.current?.value,
                BICSWIFT: BICSWIFTRef.current?.value,
            }
        });
        handleValidate()
    }

    const handleValidate = () => {

        if (postCodeRef.current?.value.match(/^\d{2}-\d{3}$/)) {
            setIsPostCode(true)
        } else {
            setIsPostCode(false)
        }

        ValidatorForm.addValidationRule('isPhone', (value) => {
            if (value && value.match(/^\+?1?\d{9,15}$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isEmpty', (value) => {

            if (value && !value.match(/^\s*$/)) {
                return true;
            }
            return false;
        });

        ValidatorForm.addValidationRule('isBICSwift', (value) => {
            if (value && value.match(/[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/)) {
                return true;
            }
            return false;
        });
        ValidatorForm.addValidationRule('isBankAccount', (value) => {
            if (value && value.match(/^[0-9]{26}$/)) {
                return true;
            }
            return false;
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setChange()
        axios.post("/users/payment-data/", {
            "name": userState.user.nameAndSurname,
            "city": userState.user.city,
            "postcode": userState.user.postcode,
            "street": userState.user.street,
            "bank_account_number": userState.user.BankAccountNumber,
            "swift_bic": userState.user.BICSWIFT,
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            })
            .then(res => {
                handleOpenSnackbar({ vertical: 'top', horizontal: 'center' }, "Twoje dane zostały zaktualizowane", "success")

            })
            .catch(err => {
                Object.entries(err.response.data).forEach(function ([key, value]) {
                    handleOpenSnackbar({ vertical: 'top', horizontal: 'center' }, value, "error")
                })
            })
    }

    const handleOpenSnackbar = (newState, message, alert) => {
        setOpenSnackbar({ ...newState, open: true });
        setAlertState(alert)
        setSnackMessage(message)
    };

    const handleClose = () => {
        setOpenSnackbar({ ...openSnackbar, open: false });
    };

    useEffect(() => {
        axios("/users/payment-data/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setUserState({
                    ...userState,
                    user: {
                        ...userState.user,
                        nameAndSurname: res.data.name === null ? "" : res.data.name,
                        city: res.data.city === null ? "" : res.data.city,
                        postcode: res.data.postcode === null ? "" : res.data.postcode,
                        street: res.data.street === null ? "" : res.data.street,

                    }

                })
                console.log("profile: ", res.data)
            })
            .catch(err => console.error(err))
    }, [])

    return (
        <ValidatorForm
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
        >
            <Box sx={{ width: 500 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    key={vertical + horizontal}
                >
                    <Alert
                        onClose={handleClose}
                        severity={alertState}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        required
                        key="nameAndUsername"
                        margin="normal"
                        fullWidth
                        id="nameAndSurname"
                        label="Imię i Nazwisko"
                        name="nameAndSurname"
                        autoComplete='new-nameAndSurname'
                        onChange={setChange}
                        type="text"
                        inputRef={nameAndSurnameRef}
                        value={userState.user.nameAndSurname}
                        validators={['required']}
                        errorMessages={['To pole jest wymagane',]}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        style={{ marginBottom: "20px" }}
                        id="standard-street"
                        label="Ulica"
                        margin="normal"
                        inputRef={streetRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.street}
                        validators={['required']}
                        errorMessages={['To pole jest wymagane']}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        required
                        style={{ marginBottom: "20px" }}
                        error={!isPostCode}
                        id="standard-zipcode"
                        label="Kod pocztowy"
                        margin="normal"
                        inputRef={postCodeRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.postcode}
                        validators={["required"]}
                        errorMessages={["Proszę podać prawidłowy kod pocztowy", "To pole jest wymagane"]}
                    />
                    {!isPostCode && <FormHelperText error>Proszę podać prawidłowy kod pocztowy </FormHelperText>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextValidator
                        style={{ marginBottom: "20px" }}
                        id="standard-city"
                        label="Miasto"
                        margin="normal"
                        inputRef={cityRef}
                        fullWidth
                        type='text'
                        onChange={setChange}
                        value={userState.user.city}
                        validators={['required']}
                        errorMessages={['To pole jest wymagane']}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator
                        required
                        key="BankAccountNumber"
                        margin="normal"
                        fullWidth
                        id="BankAccountNumber"
                        label="Numer konta bankowego"
                        name="BankAccountNumber"
                        autoComplete='new-BankAccountNumber'
                        onChange={setChange}
                        type="text"
                        inputRef={BankAccountNumberRef}
                        value={userState.user.BankAccountNumber}
                        validators={['isBankAccount', 'required']}
                        errorMessages={['Podaj prawidłowy numer konta', 'To pole jest wymagane',]}
                    />

                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextValidator
                        required
                        key="BICSWIFT"
                        margin="normal"
                        fullWidth
                        id="BICSWIFT"
                        label="Numer BIC/SWIFT"
                        name="BICSWIFT"
                        autoComplete='new-BICSWIFT'
                        onChange={setChange}
                        type="text"
                        inputRef={BICSWIFTRef}
                        value={userState.user.BICSWIFT}
                        validators={['isBICSwift', 'required']}
                        errorMessages={['Podaj prawidłowy Numer BIC/SWIFT', 'To pole jest wymagane',]}
                    />
                </Grid>
            </Grid>
            <Box display={'flex'} justifyContent={"right"}>
                <Button
                    type="submit"
                    color='secondary'
                    variant="contained"
                    sx={{ mt: 3, mb: 2, textAlign: "right" }}
                >
                    Zapisz
                </Button>
            </Box>
        </ValidatorForm>
    )
}

export default PaymentData