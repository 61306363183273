import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Lemonway from '../../assets/img/lemon-way-logo.png'
import { Box, TextField } from '@mui/material';
import { BiLogoFacebook, BiLogoLinkedin, BiLogoTiktok, BiLogoYoutube } from 'react-icons/bi';
import { FaEnvelope } from 'react-icons/fa';

const footerStyle = {
    backgroundColor: '#fff',
    color: '#fff',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center',
    width: '100%',
    marginTop: "auto",
};

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column" }} >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: { xs: "column", md: "row" } }} marginBottom={5}>
                    <Typography fontSize={16} color={"#67757c"} marginRight={2}>
                        Crowder.PRO jest agentem płatniczym Lemonway
                    </Typography>
                    <Box
                        component="img"
                        alt="Crowder"
                        src={Lemonway}
                        sx={{ maxWidth: '225px', width: '100%' }}
                    />
                </Box>
                <Box marginBottom={5}>
                    <Typography color={'#67757C'} fontSize={16} textAlign={'center'} marginBottom={2}>
                        Chcesz zostać deweloperem?
                    </Typography>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} >
                        <TextField id="outlined-size-small" size='small' type='text' placeholder='example@email.com' />
                        <Typography color={'#67757C'} fontSize={16} textAlign={'center'} textTransform={'uppercase'} marginLeft={4} >
                            Zgłoś zainteresowanie
                        </Typography>
                    </Box>
                </Box>
                <Box display={'flex'} marginBottom={2} justifyContent={'space-between'} sx={{ flexDirection: { xs: "column", md: "row" } }}>
                    <Box textAlign={'left'} marginRight={2}>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Dla inwestorów
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Regulamin platformy
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Polityka zarządzania ryzykiem
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Ostrzeżenie o ryzyku pożyczkowym
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Zastrzeżenie prawne
                            </Typography>
                        </Link>
                    </Box>
                    <Box textAlign={'left'} marginRight={2}>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Dla deweloperów
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Bezpieczeństwo
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                O nas
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Oferty
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Opłaty
                            </Typography>
                        </Link>
                    </Box>
                    <Box textAlign={'left'}>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Polityka prywatności
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Rodo
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                Blog
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }}>
                            <Typography fontSize={16} >
                                FAQ
                            </Typography>
                        </Link>
                        <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" color={'secondary'} style={{ textDecoration: 'none' }} display={'flex'} alignItems={'center'}>
                            <FaEnvelope />
                            <Typography marginLeft={1} fontSize={16} >
                                hello@crowder.pro
                            </Typography>
                        </Link>

                    </Box>
                </Box>
                <Box>
                    <Link href="https://github.com/twoja_nazwa_githuba" target="_blank" style={{ fontSize: '1.5rem', margin: '8px' }}>
                        <BiLogoFacebook color='#4abdac' />
                    </Link>
                    <Link href="https://www.linkedin.com/in/twoja_nazwa_linkedin" target="_blank" style={{ fontSize: '1.5rem', margin: '8px' }}>
                        <BiLogoLinkedin color='#4abdac' />
                    </Link>
                    <Link href="https://twitter.com/twoja_nazwa_twitter" target="_blank" style={{ fontSize: '1.5rem', margin: '8px' }}>
                        <BiLogoYoutube color='#4abdac' />
                    </Link>
                    <Link href="https://twitter.com/twoja_nazwa_twitter" target="_blank" style={{ fontSize: '1.5rem', margin: '8px' }}>
                        <BiLogoTiktok color='#4abdac' />
                    </Link>
                </Box>
            </Container>
            <Box display={"flex"} justifyContent={'space-between'} px={2}>
                <Typography variant="body2" color={"#67757c"}>
                    © {new Date().getFullYear()} Crowder
                </Typography>
                <Typography variant="body2" color={"#67757c"}>
                    WERSJA BETA
                </Typography>
            </Box>
        </footer>
    )
}

export default Footer