import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container, Alert } from "@mui/material";
import bgImage from "../../../assets/img/bg-sign-up.png";
import Logo from "../../../assets/img/logo_new.png";

const ResetCrowder = ({ handleResetPassword, resetMessage, alertMode }) => {

    return (
        <Container
            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                WebkitBackgroundSize: "cover",
                width: "100%",
                maxWidth: "none"
            }}
            style={{
                maxWidth: "none",
                padding: "0px"
            }}
        >
            <Box

            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        sx={{ background: "#ffffff", height: "100vh", width: "100%" }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                height: "70px",
                                margin: "48px 32px"
                            }}
                        >
                            <Box
                                component="img"
                                alt="Crowder"
                                src={Logo}
                            />
                        </Box>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "left",
                            }}
                        >
                            <Typography component="h1" variant="h3" sx={{ marginBottom: "10px" }}>
                                Odzyskaj hasło
                            </Typography>
                            <Typography component="p" fontSize={14} sx={{ marginBottom: "10px" }}>
                                Podaj e-mail na który zostało założone konto, dostaniesz na niego informacje jak zmeinić hasło.
                            </Typography>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleResetPassword}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Zresetuj hasło
                                </Button>
                                {
                                    resetMessage
                                    &&
                                    <Alert severity={alertMode} sx={{ mb: 4 }} >{resetMessage}</Alert>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default ResetCrowder