import { useState, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Verification from "./Verification";
import LoginForm from "./LoginForm";
import ResetCrowder from "./ResetCrowder";
import { ValidatorForm } from 'react-material-ui-form-validator';
import VerificationReset from "./VerificationReset";
import ResetPasswordFinal from "./ResetPasswordFinal";

const Login = () => {

    const { setAuth } = useAuth()

    const passwordResetFinalRef = useRef();
    const passwordResetFinalConfRef = useRef();

    const ref = {
        passwordResetFinalRef: passwordResetFinalRef,
        passwordResetFinalConfRef: passwordResetFinalConfRef
    }

    const [loginMessage, setLoginMessage] = useState("")
    const [resetMessage, setResetMessage] = useState("")
    const [twofaMessage, setTwofaMessage] = useState("")
    const [passwordResetFinalState, setResetFinalState] = useState({
        passwordReset: "",
        passwordResetConfirm: ""
    })
    const [verifyResetMessage, setVerifyResetMessage] = useState(null)
    const [resetFinalMessage, setResetFinalMessage] = useState(null)
    const [resetFinalError1Message, setResetFinalError1Message] = useState(null)
    const [resetFinalError2Message, setResetFinalError2Message] = useState(null)
    const [resetFinalError3Message, setResetFinalError3Message] = useState(null)
    const [alertMode, setAlertMode] = useState("error")
    const [tokenOtp, setTokenOtp] = useState("")
    const [loginMode, setLoginMode] = useState(0)
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const username = data.get("email");
        const password = data.get("password");

        axios.post('/token/authorize/',
            {
                "username": username,
                "password": password,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if (res.data.otp_required) {
                setLoginMode(1)
                setTokenOtp(res.data.access)
            } else if (res.data.access) {
                localStorage.setItem("token", res.data.access)
                localStorage.setItem("refresh", res.data.refresh)
                const accessToken = localStorage.getItem("token")
                setAuth({ username, accessToken })
                navigate("/investments");
            }
        }
        ).catch(err => {
            setLoginMessage(null)
            setTimeout(() => {
                if (err.response) {
                    setLoginMessage(err.response.data.non_field_errors)
                } else {
                    setLoginMessage("Błąd serwera")
                }
            }, 1000)

        });
    };

    const handleTwoFa = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const code = data.get("code");
        axios.post('/token/otp-confirm/',
            {
                "access": tokenOtp,
                "code": code
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            localStorage.setItem("tokenOtp", res.data.refresh)
            setTokenOtp(res.data.refresh)
            setAuth({ accessToken: tokenOtp })
            navigate("/investments");
        }
        ).catch(err => {
            if (err.response.data.code) {
                setTwofaMessage(err.response.data.code[0])
            } else if (err.response.data.non_field_errors) {
                setTwofaMessage(err.response.data.non_field_errors[0])
            } else {
                setTwofaMessage("Błąd serwera")
            }
        });
    }
    const onLoginStart = useCallback((res) => {
        navigate('/login')
    }, [navigate]);

    const onResolve = ({ provider, data }) => {
        if (provider === "google") {
            loginGoogle(data.access_token)
        }
    }

    const onLogoutSuccess = useCallback(() => {
        console.log('logout success');
    }, []);
    const loginGoogle = (data) => {
        axios.post(`/social/google-oauth2/`, {
            access_token: data
        },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if (res.data.otp) {
                setLoginMode(1)
                setTokenOtp(res.data.access)
            } else if (res.data.access) {
                localStorage.setItem("token", res.data.access)
                localStorage.setItem("refresh", res.data.refresh)
                const accessToken = localStorage.getItem("token")
                setAuth({ accessToken })
                navigate("/investments");
            }
        }
        ).catch(err => {
            if (err.response) {
                setLoginMessage(err.response.data.non_field_errors)
            } else {
                setLoginMessage("Błąd serwera")
            }
        });
    }

    const resetPassword = () => {
        setLoginMode(2)
    }

    const handleResetPassword = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const email = data.get("email");

        axios.post('/users/password/reset/request/',
            {
                "email": email,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            setAlertMode("success")
            localStorage.setItem("access", res.data.access)
            setTimeout(() => {
                setLoginMode(3)
            }, 2000)
        }).catch(err => {
            setAlertMode("error")
            if (err.response) {
                setResetMessage(err.response.data.email[0])
            } else {
                setResetMessage("Błąd serwera")
            }

        })
    }

    const renderMode = (mode) => {
        switch (mode) {
            case 0:
                return (
                    <LoginForm
                        handleSubmit={handleSubmit}
                        loginMessage={loginMessage}
                        loginGoogle={loginGoogle}
                        resetPassword={resetPassword}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={onResolve}
                    />
                )
            case 1:
                return (
                    <Verification
                        tokenOtp={tokenOtp}
                        handleTwoFa={handleTwoFa}
                        twofaMessage={twofaMessage}
                    />
                )
            case 2:
                return (
                    <ResetCrowder
                        handleResetPassword={handleResetPassword}
                        resetMessage={resetMessage}
                        alertMode={alertMode}
                    />
                )
            case 3:
                return (
                    <VerificationReset
                        handleVerifyResetSubmit={handleVerifyResetSubmit}
                        verifyResetMessage={verifyResetMessage}
                    />
                )
            case 4:
                return (
                    <ResetPasswordFinal
                        ref={ref}
                        handleResetFinalSubmit={handleResetFinalSubmit}
                        passwordResetFinalState={passwordResetFinalState}
                        setResetFinalChange={setResetFinalChange}
                        resetFinalMessage={resetFinalMessage}
                        resetFinalError1Message={resetFinalError1Message}
                        resetFinalError2Message={resetFinalError2Message}
                        resetFinalError3Message={resetFinalError3Message}
                    />
                )
            default:
                return (
                    <LoginForm
                        handleSubmit={handleSubmit}
                        loginMessage={loginMessage}
                        loginGoogle={loginGoogle}
                        resetPassword={resetPassword}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={onResolve}
                    />
                )
        }
    }

    const handleResetFinalValidate = () => {

        ValidatorForm.addValidationRule('isPasswordLongEnough', (value) => {
            if (value.length < 8) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isPasswordSpecial', (value) => {
            if (value && value.match(/^(?=.*[!@#$%^&*()_+{}\]:;<>,.?~\\|/\\-])(?=.*\d).+$/)) {
                return true;
            }
            return false;
        });
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            const formData = passwordResetFinalState;
            if (value !== formData.passwordReset) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isEmpty', (value) => {

            if (value && !value.match(/^\s*$/)) {
                return true;
            }
            return false;
        });
    }

    const setResetFinalChange = () => {
        setResetFinalState({
            ...passwordResetFinalState,
            passwordReset: passwordResetFinalRef.current?.value,
            passwordResetConfirm: passwordResetFinalConfRef.current?.value
        });
        handleResetFinalValidate()
    }

    const handleResetFinalSubmit = (e) => {
        e.preventDefault();
        handleResetFinalValidate()
        axios.post('users/password/reset/confirm/',
            {
                "access": localStorage.getItem("access"),
                "code": localStorage.getItem("codeReset"),
                "password": passwordResetFinalState.passwordReset,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            setResetFinalMessage("Sukces!")
            setTimeout(() => {
                setLoginMode(0)
            }, 2000)
        }
        ).catch(err => {
            setResetFinalError1Message(err.response.data.password)
            setResetFinalError2Message(err.response.data.code)
            setResetFinalError3Message(err.response.data.non_field_errors)
        });
    };
    const handleVerifyResetSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const code = data.get("code");

        axios.post('users/password/reset/check/',
            {
                "access": localStorage.getItem("access"),
                "code": code
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            localStorage.setItem("codeReset", code)
            setTimeout(() => {
                setLoginMode(4)
            }, 2000)
        }
        ).catch(err => {
            setVerifyResetMessage(err.response.data)
        });
    }

    return (
        <>
            {
                renderMode(loginMode)
            }
        </>
    );
}

export default Login;