import { Box, CircularProgress } from "@mui/material"
import axios from "axios"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const LoginAs = () => {
    const navigate = useNavigate()
    const { setAuth } = useAuth()

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        console.log("authCode: ", searchParams.getAll('code').toString())
        axios.post('/token/authorize-by-code/',
            {
                "code": searchParams.getAll('code').toString(),
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            console.log("auth: ", res)
            localStorage.setItem("token", res.data.access)
            localStorage.setItem("refresh", res.data.refresh)
            const accessToken = localStorage.getItem("token")
            setAuth({ accessToken })
            navigate("/investments");
        })
            .catch(err => {
                console.error("autherr: ", err)
            })
    }, [])

    return (
        <Box sx={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress color="secondary" size={200} sx={{ borderRadius: "20px", }} />
        </Box>
    )
}

export default LoginAs