import { Container, Alert, Box, Typography, Button, TextField } from "@mui/material";

const VerificationReset = ({ handleVerifyResetSubmit, verifyResetMessage }) => {

    return (
        <Container >
            <Box
                component="form"
                noValidate
                onSubmit={handleVerifyResetSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    px: "23%",
                }}
            >
                <Typography component="h1" variant="h3" sx={{ marginBottom: "10px", textAlign: "center" }}>Wprowadź kod weryfikacyjny</Typography>
                <Typography component="p" fontSize={14} sx={{ marginBottom: "10px", textAlign: "center" }}>Ze względów bezpieczeństwa na Twój adres email zostanie wysłany 6-cyfrowy kod autoryzacyjny ważny przez 30 min. Aby się zalogować podaj kod</Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Kod"
                    placeholder="1234"
                    name="code"
                    type="text"
                    autoComplete="none"
                    autoFocus
                    variant="filled"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Potwierdź
                </Button>
                {
                    verifyResetMessage &&
                    <Alert sx={{ mb: 4 }} severity="error"> {verifyResetMessage}</Alert>
                }
            </Box>
        </Container>
    )
}

export default VerificationReset;