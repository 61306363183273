import { Box, Skeleton } from '@mui/material';

const InvestmentLoader = () => {
    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "space-between" }}>
            <Box width={"100%"} marginRight={2}>
                <Skeleton variant="rectangular" width="100%" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
            <Box width={"100%"} marginRight={2}>
                <Skeleton variant="rectangular" width="100%" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
            <Box width={"100%"} >
                <Skeleton variant="rectangular" width="100%" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
        </Box>
    )
}

export default InvestmentLoader