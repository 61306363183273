import { TextValidator, } from 'react-material-ui-form-validator';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { Link } from "react-router-dom";
import { forwardRef } from 'react';

const FirstStep = ({ setChange, userState, validatorCustomTerm, validatorCustomRodo }, ref) => {

    const {
        emailRef,
        passwordRef,
        passwordConfRef,
        consentToTermsRef,
        consentToRodoRef,
        consentToMarketingRef,
        consentToTelemarketingRef
    } = ref

    return (
        <>
            <TextValidator
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete='new-email'
                autoFocus
                onChange={setChange}
                type="email"
                inputRef={emailRef}
                value={userState.user.email}
                validators={['isEmpty', 'isEmailMatch', 'required']}
                errorMessages={['To pole jest wymagane', 'Podaj poprawny adres e-mail.']}
            />
            <TextValidator
                required
                id="standard-required-password"
                label="Hasło"
                margin="normal"
                autoComplete='new-password'
                inputRef={passwordRef}
                fullWidth
                type='password'
                onChange={setChange}
                value={userState.user.password}
                validators={['isEmpty', 'isPasswordLongEnough', 'isPasswordSpecial', 'required']}
                errorMessages={['To pole jest wymagane', 'Użyj hasła składającego się z 8 lub więcej znaków', 'hasło musi mieć znaki specjalne oraz liczby']}
            />
            <TextValidator
                style={{ marginBottom: "20px" }}
                required
                id="standard-required-passwordConfirm"
                label="Potwierdź hasło"
                placeholder='(Potwierdź hasło)'
                margin="normal"
                inputRef={passwordConfRef}
                fullWidth
                type='password'
                onChange={setChange}
                value={userState.passwordConfirm}
                validators={['isPasswordMatch', 'required']}
                errorMessages={['Hasło się nie zgadza', 'To pole jest wymagane']}
            />
            <FormControl
                error={!userState.user.consent_to_terms}
            >
                <FormControlLabel
                    sx={{ display: "flex", alignItems: "flex-start", marginBottom: "10px", paddingTop: "0", fontSize: 13 }}
                    control={
                        <Checkbox
                            required
                            inputRef={consentToTermsRef}
                            value={userState.user.consent_to_terms}
                            onChange={setChange}
                            sx={{ marginTop: "-7px" }}
                            color={"secondary"}
                        />
                    }
                    label={
                        <div style={{ fontSize: 13 }} >Oświadczam, że zapoznałem się i akceptuję treść „
                            <Link
                                to={"/useragreement"}
                                target='_blank'
                                style={{ color: "#38b8ab" }}
                            >
                                Regulaminu platformy crowdlendingu (crowdfundingu pożyczkowego) Crowder.Pro
                            </Link>”.
                        </div>
                    }
                />
                {
                    !userState.user.consent_to_terms &&
                    <FormHelperText >Akceptacja regulaminu jest obowiązkowa</FormHelperText>
                }
            </FormControl >
            <FormControl
                error={!userState.user.consent_to_rodo}
            >
                <FormControlLabel
                    required
                    sx={{ display: "flex", alignItems: "flex-start", marginBottom: "10px", fontSize: 13 }}
                    control={
                        <Checkbox
                            inputRef={consentToRodoRef}
                            value={userState.user.consent_to_rodo}
                            onChange={setChange}
                            sx={{ marginTop: "-7px" }}
                            color={"secondary"}
                        />
                    }
                    label={
                        <div style={{ fontSize: 13 }}>
                            Oświadczam, że zapoznałem się i akceptuję treść „
                            <Link
                                to={"/termsofuse"}
                                target='_blank'
                                style={{ color: "#38b8ab", fontSize: 13 }}
                            >
                                Polityki prywatności Crowder.Pro
                            </Link>”.
                        </div>
                    }
                />
                {
                    !userState.user.consent_to_rodo &&
                    <FormHelperText >Akceptacja RODO jest obowiązkowa</FormHelperText>
                }
            </FormControl>
            <FormControlLabel
                style={{ fontSize: 13 }}
                sx={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" }}
                control={
                    <Checkbox
                        inputRef={consentToMarketingRef}
                        value={userState.user.consent_to_marketing}
                        onChange={setChange}
                        sx={{ marginTop: "-7px" }}
                        color={"secondary"}
                    />
                }
                label={<div style={{ fontSize: 13 }}>Wyrażam zgodę na otrzymywanie od Crowder.Pro newslettera i informacji handlowych za pośrednictwem środków komunikacji elektronicznej na podstawie artykułu 10 ustawy o świadczeniu usług drogą elektroniczną.</div>}
            />
            <FormControlLabel
                sx={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" }}
                control={
                    <Checkbox
                        inputRef={consentToTelemarketingRef}
                        value={userState.user.consent_to_telemarketing}
                        onChange={setChange}
                        sx={{ marginTop: "-7px" }}
                        color={"secondary"}
                    />
                }
                label={<div style={{ fontSize: 13 }}>Wyrażam zgodę na wykorzystywanie przez Crowder.Pro telekomunikacyjnych i automatycznych systemów wywołujących w celu marketingu bezpośredniego produktów oferowanych przez Crowder.Pro na podstawie Art.172 ust 1 Ustawy Prawo telekomunikacyjne."</div>}
            />
        </>
    )
}

export default forwardRef(FirstStep);