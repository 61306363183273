import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

export default function CircleModal({ icon, iconpopupstyle, circleStyle }) {
    return (
        <Box sx={iconpopupstyle}>
            <Fab color={'secondary'} aria-label="phone" sx={circleStyle} >
                {icon}
            </Fab>
        </Box>
    );
}