import { useState, useEffect } from "react";
import { CircularProgress } from '@mui/material';
import axios from "axios";
import CrowderTabPanelMyInvestments from "../CrowderTabPanelMyInvestments";
import TableRows from "../CrowderTabPanelMyInvestments/TableRows";
import InvestedAmountChart from "../CrowderTabPanelMyInvestments/InvestedAmountChart";
import uuid from "react-uuid";

const MyInvestments = () => {

    const [loadingCounts, setLoadingCounts] = useState(true)
    const [countsActual, setCountsActual] = useState(0)
    const [loadingChart, setLoadingChart] = useState(true)
    const [countsSuccess, setCountsSuccess] = useState(0)
    const [countsVindication, setCountsVindication] = useState(0)
    const [countsClosed, setCountsClosed] = useState(0)
    const [countsFailure, setCountsFailure] = useState(0)
    const [projectsActual, setProjectsActual] = useState([])
    const [projectsSuccess, setProjectsSuccess] = useState([])
    const [projectsFailed, setProjectsFailed] = useState([])
    const [projectsClosed, setProjectsClosed] = useState([])
    const [projectsVindication, setProjectsVindication] = useState([])
    const [projectName, setProjectName] = useState('')
    const [product, setProduct] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(0)
    const [isRowsPerPage, setIsRowsPerPage] = useState(false)
    const [filterByPeriod, setFilterByPeriod] = useState('')
    const [tabValue, setTabValue] = useState(0)
    const [offsetPage, setOffsetPage] = useState(0)
    const [totalInvestmentActual, setTotalInvestmentActual] = useState(0)
    const [totalInvestmentSuccess, setTotalInvestmentSuccess] = useState(0)
    const [totalInvestmentFailure, setTotalInvestmentFailure] = useState(0)
    const [totalInvestmentClosed, setTotalInvestmentClosed] = useState(0)
    const [totalInvestmentVindication, setTotalInvestmentVindication] = useState(0)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                getProjectsFilterAndSort(null, null, "open", null, null, null, 0)
                break
            case 1:
                getProjectsFilterAndSort(null, null, "success", null, null, null, 1)
                break
            case 2:
                getProjectsFilterAndSort(null, null, "failure", null, null, null, 2)
                break
            case 3:
                getProjectsFilterAndSort(null, null, "closed", null, null, null, 3)
                break
            case 4:
                getProjectsFilterAndSort(null, null, "vindication", null, null, null, 4)
                break
            default:
                getProjectsFilterAndSort(null, null, "open", null, null, null, 0)
        }
        setOffsetPage(0)
        setIsRowsPerPage(false)
        setRowsPerPage(0)
    };

    const TabsEl = [{
        id: 0,
        pl: <div>Trwające ({loadingCounts ? <CircularProgress size={15} /> : countsActual})</div>,
        link: "",
        projectCounts: countsActual,
        totalInvestedAmount: totalInvestmentActual,
        investedAmountChart: <InvestedAmountChart totalInvestment={totalInvestmentActual} loadingChart={loadingChart} projects={projectsActual.length > 0 ? projectsActual : [{ investedAmount: 100 }]} colorChart={projectsActual.length < 1 && "#A0A0A0"} circularSize={300} />,
        Content: projectsActual.map(el => {
            return (
                <TableRows
                    key={uuid()}
                    title={el.title}
                    status={el.status}
                    investedAmount={el.investedAmount}
                    totalYield={el.totalYield}
                    foundsToBeWithdrawn={el.foundsToBeWithdrawn}
                    endDate={el.endDate}
                />
            )
        })
    },
    {
        id: 1,
        pl: <div>Sfinansowane ({loadingCounts ? <CircularProgress size={15} /> : countsSuccess})</div>,
        projectCounts: countsSuccess,
        totalInvestedAmount: totalInvestmentSuccess,
        investedAmountChart: <InvestedAmountChart totalInvestment={totalInvestmentSuccess} loadingChart={loadingChart} projects={projectsSuccess.length > 0 ? projectsSuccess : [{ investedAmount: 100 }]} circularSize={300} colorChart={projectsActual.length < 1 && "#A0A0A0"} />,
        link: "",
        Content: projectsSuccess.map(el => {
            return (
                <TableRows
                    title={el.title}
                    status={el.status}
                    investedAmount={el.investedAmount}
                    totalYield={el.totalYield}
                    foundsToBeWithdrawn={el.foundsToBeWithdrawn}
                    endDate={el.endDate}
                />
            )
        })
    },
    {
        id: 2,
        pl: <div>Niesfinansowane ({loadingCounts ? <CircularProgress size={15} /> : countsFailure})</div>,
        projectCounts: countsFailure,
        totalInvestedAmount: totalInvestmentFailure,
        investedAmountChart: <InvestedAmountChart totalInvestment={totalInvestmentFailure} loadingChart={loadingChart} projects={projectsFailed.length ? projectsFailed : [{ investedAmount: 100 }]} circularSize={300} colorChart={projectsActual.length < 1 && "#A0A0A0"} />,
        link: "",
        Content: projectsFailed.map(el => {
            return (
                <TableRows
                    title={el.title}
                    status={el.status}
                    investedAmount={el.investedAmount}
                    totalYield={el.totalYield}
                    foundsToBeWithdrawn={el.foundsToBeWithdrawn}
                    endDate={el.endDate}
                />
            )
        })
    },
    {
        id: 3,
        pl: <div>Zamknięte ({loadingCounts ? <CircularProgress size={15} /> : countsClosed})</div>,
        projectCounts: countsClosed,
        totalInvestedAmount: totalInvestmentClosed,
        investedAmountChart: <InvestedAmountChart totalInvestment={totalInvestmentClosed} loadingChart={loadingChart} projects={projectsClosed.length ? projectsClosed : [{ investedAmount: 100 }]} circularSize={300} colorChart={projectsActual.length < 1 && "#A0A0A0"} />,
        link: "",
        Content: projectsClosed.map(el => {
            return (
                <TableRows
                    title={el.title}
                    status={el.status}
                    investedAmount={el.investedAmount}
                    totalYield={el.totalYield}
                    foundsToBeWithdrawn={el.foundsToBeWithdrawn}
                    endDate={el.endDate}
                />
            )
        })
    },
    {
        id: 4,
        pl: <div>W windykacji ({loadingCounts ? <CircularProgress size={15} /> : countsVindication})</div>,
        projectCounts: countsVindication,
        totalInvestedAmount: totalInvestmentVindication,
        investedAmountChart: <InvestedAmountChart totalInvestment={totalInvestmentVindication} loadingChart={loadingChart} projects={projectsVindication.length ? projectsVindication : [{ investedAmount: 100 }]} circularSize={300} colorChart={projectsActual.length < 1 && "#A0A0A0"} />,
        link: "",
        Content: projectsVindication.map(el => {
            return (
                <TableRows
                    title={el.title}
                    status={el.status}
                    investedAmount={el.investedAmount}
                    totalYield={el.totalYield}
                    foundsToBeWithdrawn={el.foundsToBeWithdrawn}
                    endDate={el.endDate}
                />
            )
        })
    },
    ]

    const setProjectsType = (projectsType, pendingArr) => {
        let totalInvest = 0
        switch (projectsType) {
            case 0:
                setProjectsActual(pendingArr)
                pendingArr.map(el => {
                    return (
                        totalInvest = totalInvest + el.investedAmount
                    )
                })
                setTotalInvestmentActual(totalInvest)
                break
            case 1:
                setProjectsSuccess(pendingArr)
                pendingArr.map(el => {
                    return (
                        totalInvest = totalInvest + el.investedAmount
                    )
                })
                setTotalInvestmentSuccess(totalInvest)
                break
            case 2:
                setProjectsFailed(pendingArr)
                pendingArr.map(el => {
                    return (
                        totalInvest = totalInvest + el.investedAmount
                    )
                })
                setTotalInvestmentFailure(totalInvest)
                break
            case 3:
                setProjectsClosed(pendingArr)
                pendingArr.map(el => {
                    return (
                        totalInvest = totalInvest + el.investedAmount
                    )
                })
                setTotalInvestmentClosed(totalInvest)
                break
            case 4:
                setProjectsVindication(pendingArr)
                pendingArr.map(el => {
                    return (
                        totalInvest = totalInvest + el.investedAmount
                    )
                })
                setTotalInvestmentVindication(totalInvest)
                break
            default:
                setProjectsActual(pendingArr)
                pendingArr.map(el => {
                    return (
                        totalInvest = totalInvest + el.investedAmount
                    )
                })
                setTotalInvestmentActual(totalInvest)
        }
    }

    const handleFilterRows = (filterBy) => {
        if (localStorage.getItem('refresh') !== '' && localStorage.getItem('refresh') !== null) {
            getRefresh()
        }
        setRowsPerPage(filterBy)
        switch (tabValue) {
            case 0:
                getProjectsFilterAndSort(null, null, "open", null, filterBy, null, 0)
                break
            case 1:
                getProjectsFilterAndSort(null, null, "success", null, filterBy, null, 0)
                break
            case 2:
                getProjectsFilterAndSort(null, null, "failure", null, filterBy, null, 0)
                break
            case 3:
                getProjectsFilterAndSort(null, null, "closed", null, filterBy, null, 0)
                break
            case 4:
                getProjectsFilterAndSort(null, null, "vindication", null, filterBy, null, 0)
                break
            default:
                getProjectsFilterAndSort(null, null, "open", null, filterBy, null, 0)
        }
        setIsRowsPerPage(true)
    };

    const handlePage = (e, value) => {

        const offset = value * rowsPerPage - rowsPerPage
        setOffsetPage(offset)
        switch (tabValue) {
            case 0:
                getProjectsFilterAndSort(null, null, "open", null, rowsPerPage, offset, 0)
                break
            case 1:
                getProjectsFilterAndSort(null, null, "success", null, rowsPerPage, offset, 0)
                break
            case 2:
                getProjectsFilterAndSort(null, null, "failure", null, rowsPerPage, offset, 0)
                break
            case 3:
                getProjectsFilterAndSort(null, null, "closed", null, rowsPerPage, offset, 0)
                break
            case 4:
                getProjectsFilterAndSort(null, null, "vindication", null, rowsPerPage, offset, 0)
                break
            default:
                getProjectsFilterAndSort(null, null, "open", null, rowsPerPage, offset, 0)
        }

    }

    const getProjectsFilterAndSort = async (
        filterBy,
        productType,
        status,
        sortBy,
        limit,
        offset,
        projectsMode,
    ) => {
        return await axios("/investments/my-investments/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                "project__status": status,
                "ordering": sortBy,
                "limit": limit,
                "offset": offset
            }
        })
            .then((res) => {
                const data = res.data.results
                let pendingArr = []
                data.forEach((el, i) => {
                    pendingArr.push(
                        {
                            productType: el.product_type,
                            title: el.project.name,
                            location: el.project.address,
                            scoringRate: el.scoring_rate,
                            CardImg: el.picture !== null && el.picture ? el.picture.small : null,
                            investedAmount: el.project.invested_amount,
                            requiredAmount: el.required_amount,
                            investedPercentage: el.invested_percentage,
                            annualYield: el.project.annual_yield,
                            investorsNo: el.project.investors_no,
                            totalYield: el.project.total_yield,
                            endDate: el.project.end_time,
                            loanStartDate: el.loan_start_date,
                            variableInterestVisible: el.variable_interest_visible,
                            status: el.status,
                            foundsWithdrawn: el.founds_withdrawn,
                            foundsToBeWithdrawn: el.founds_to_be_withdrawn
                        })
                })
                setProjectsType(projectsMode, pendingArr)
                setLoadingChart(false)
            })
            .catch((err) => {
                console.error(err)
                setLoadingChart(false)
            })
    }

    const handleSortByChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleFilterByPeriodByChange = (e) => {
        setFilterByPeriod(e.target.value);
    }

    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
    };

    const handleProductChange = (e) => {
        setProduct(e.target.value);
    };


    const getRefresh = async () => {
        axios.post('/token/refresh/',
            {
                "refresh": localStorage.getItem("refresh"),
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(userData => {
            localStorage.setItem("token", userData.data.access)
        }).catch(err => {
            console.error(err)
        })
    }

    const getProjectsCount = async () => {
        axios("/projects/counts/my/", {
            'Content-Type': 'application/json',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setCountsActual(res.data.open)
                setCountsSuccess(res.data.success)
                setCountsClosed(res.data.closed)
                setCountsVindication(res.data.vindication)
                setCountsFailure(res.data.failure)
                setLoadingCounts(false)
            })
            .catch(err => {
                setLoadingCounts(false)
                console.error(err)
            })
    }

    useEffect(() => {
        getRefresh()
        getProjectsCount()
        handleChange(null, 0)
    }, [])

    return (
        <CrowderTabPanelMyInvestments
            handleFilterByPeriodByChange={handleFilterByPeriodByChange}
            handleProductChange={handleProductChange}
            handleProjectNameChange={handleProjectNameChange}
            handleSortByChange={handleSortByChange}
            handleFilterRows={handleFilterRows}
            filterByPeriod={filterByPeriod}
            TabsEl={TabsEl}
            tabValue={tabValue}
            handleChange={handleChange}
            rowsPerPage={rowsPerPage}
            isRowsPerPage={isRowsPerPage}
            handlePage={handlePage}
            offsetPage={offsetPage}
        />
    );
}

export default MyInvestments;