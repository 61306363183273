import { Box, Button, InputLabel, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: "flex",
    flexDirection: "column",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const VerifyModal = ({ handleOpen, handleClose, open, codeValue, handleSubmit, setChange }) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <InputLabel>Kod weryfikacji</InputLabel>
                <TextField type='text' value={codeValue} onChange={setChange} marginBottom={1} />
                <Button variant='contained' onClick={handleSubmit} >Potwierdź kod</Button>
            </Box>
        </Modal>
    );
}

export default VerifyModal;