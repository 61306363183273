import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const BarChart = ({ chartData, options }) => {
    return <Bar data={chartData} options={options} />;
}

export default BarChart;