import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import uuid from 'react-uuid';
import { useState } from 'react';
import Calculator from '../../../Calculator';
import InvestmentDescription from './InvestmentDescription';
import Documentation from './Documentation';

const CustomTab = styled(Tab)(({ theme, selected }) => ({
    backgroundColor: selected ? '#fff' : 'inherit', // Background color for active tab
}));

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Description = ({ annualYield, totalYield, requiredAmountFormatted, requiredAmount, dividendPaymentDates, id }) => {
    const [tabValue, setTabValue] = useState(0);
    const plnTotalYield = totalYield / 100 * requiredAmount

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const TabsEl = [
        {
            pl: "Opis inwestycji"
        },
        {
            pl: "Finanse"
        },
        {
            pl: "Dokumentacja"
        },
        {
            pl: "Scoring"
        }
    ]

    return (
        <Box sx={{ background: "#fff", width: 'calc(100% - 40px)', padding: "20px", marginBottom: "30px" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="fullWidth"
                    value={tabValue}
                    onChange={handleChange}
                >
                    {
                        TabsEl.map((tabel, i) => {
                            return (
                                <CustomTab key={uuid()} label={tabel.pl} {...a11yProps(i)} />
                            )
                        })
                    }
                </Tabs>
            </Box>
            <CustomTabPanel key={uuid()} value={tabValue} index={0}>
                <InvestmentDescription id={id} />
            </CustomTabPanel>
            <CustomTabPanel key={uuid()} value={tabValue} index={1} >
                <Box sx={{ display: "flex", justifyContent: "space-around" }} marginBottom={3}>
                    <Box>
                        <Typography color="#67757c" fontSize={11} fontWeight={300} >
                            Roczna stopa zwrotu
                        </Typography>
                        <Typography color="#67757c" fontSize={32} fontWeight={300} textAlign={"center"}>
                            {annualYield}%
                        </Typography>

                    </Box>
                    <Box>
                        <Typography color="#67757c" fontSize={11} fontWeight={300} >
                            Zysk całkowity
                        </Typography>
                        <Typography color="#67757c" fontSize={32} fontWeight={300} textAlign={"center"}>
                            {totalYield}%
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color="#67757c" fontSize={11} fontWeight={300} >
                            Długość pożyczki
                        </Typography>
                        <Typography color="#67757c" fontSize={32} fontWeight={300} textAlign={"center"}>
                            0 m-cy
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", }}>
                    <Box sx={{ width: "calc(50% - 25px)" }}>
                        <Typography color={"primary"} fontWeight={700}>Dane finansowe</Typography>
                        <Divider />
                        <Box sx={{ display: "flex", justifyContent: "space-between" }} py={1}>
                            <Typography color={"primary"} fontWeight={300}>Docelowa kwota</Typography>
                            <Typography color={"primary"} fontWeight={300}>{requiredAmountFormatted} PLN</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: "50%", marginLeft: "10px" }}>
                        <Typography sx={{ marginLeft: "5px", }} color={"primary"} fontWeight={700}>Analiza zysków</Typography>
                        <Divider />
                        <Box sx={{ display: "flex", justifyContent: "space-between" }} py={1}>
                            <Typography color={"primary"} fontWeight={300}>Całkowity zysk</Typography>
                            <Typography color={"primary"} fontWeight={300}>{plnTotalYield.toFixed(2)} PLN</Typography>
                        </Box>
                    </Box>
                </Box>
                <Calculator top={"20px"} />
                <Typography>Oprocentowanie wypłacane na koniec inwestycji:</Typography>
                {
                    dividendPaymentDates
                    &&
                    dividendPaymentDates.map(el => {
                        return (
                            <Typography key={uuid()}>{el.date}</Typography>
                        )
                    })
                }
            </CustomTabPanel>
            <CustomTabPanel key={uuid()} value={tabValue} index={2}>
                <Documentation />
            </CustomTabPanel>
            <CustomTabPanel key={uuid()} value={tabValue} index={3}>
                <Typography fontSize={21} color={"#455a64"} fontWeight={500} marginBottom={3}>
                    Ocena scoringowa
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300} marginBottom={2}>
                    Crowder.PRO stosuje autorski, unikatowy system scoringowy do badania jakości i atrakcyjności inwestycyjnej projektu inwestycyjnego. W ramach systemu badane są parametry związane z:
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    lokalizacją inwestycji, podażą nieruchomości konkurencyjnych i popytem na nieruchomości
                    dostępnością dróg lokalnych i infrastruktury
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    atrakcyjnością cenową inwestycji i proponowaną ceną sprzedaży nieruchomości
                    kosztami budowy i kosztami projektowania
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300} marginBottom={2}>
                    wskaźnikami finansowymi i rentowności sponsora projektu i jego doświadczeniem
                    oceną rentowności projektowanej inwestycji (przed kosztami finansowymi) i zdolnością do obsługi zadłużenia.
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300} marginBottom={2}>
                    W systemie scoringowym przeprowadzane są dwa typy analizy: danych statystycznych oraz danych dynamicznych. Analiza statyczna: polega na analizie danych pobranych w określonym momencie. Dane dotyczą wykonawcy projektu i jego gwarancji, a w szczególności uwzględniane są wskaźniki związane z:
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    doświadczeniem dewelopera
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    liczbą uruchomionych projektów
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    uzyskiwaną rentowność projektów
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    stopą zadłużenia / wartość netto
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    wskaźnikami zarządzania aktywami, w tym zadłużenia
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300} marginBottom={2}>
                    wskaźnikiem czasu trwania projektu vs. opóźnienia w realizacji.
                </Typography>
                <Typography fontSize={14} color={"#455a64"} fontWeight={300}>
                    Analiza dynamiczna polega na analizie danych, które mogą mieć miejsce podczas całego życia projektu. Przyglądamy się m.in. wskaźnikom przed-wynajmu (dla projektów komercyjnych) czy przed-sprzedaży (dla projektów mieszkaniowych), planowaną rentownością (wskaźnik ROI czy wewnętrzną stopą zwrotu IRR), kosztom budowy. W efekcie każdy projekt analizowany przez zespół Crowdera otrzymuje ocenę atrakcyjności od AAA – projekt wysokiej atrakcyjności /niskiego ryzyka, do C – projekt niskiej atrakcyjności inwestycyjnej /wysokiego ryzyka.
                </Typography>
            </CustomTabPanel>
        </Box>
    );
}

export default Description;