import { useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { Line } from 'react-chartjs-2';

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color
}

const LineLast12Chart = ({ projectsProfitMonthly, statsLoader }) => {

    const [last12Months, setLast12Months] = useState([])
    const [color, setColor] = useState([])

    const generateLast12Months = () => {
        const currentDate = new Date();
        const months = [];
        const monthNames = [
            'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec',
            'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'
        ];
        for (let i = 0; i < 12; i++) {
            const month = currentDate.getMonth() - i;
            const year = currentDate.getFullYear();
            months.push({
                month: monthNames[month < 0 ? 14 - i : month],
                year: month < 0 ? year - 1 : year,
            });
        }

        setLast12Months(months.reverse());
    };

    const dataMonthly = {
        labels: last12Months.map(month => { return `${month.month} ${month.year}` }),
        datasets: projectsProfitMonthly.map((profit, i) => {
            return {
                label: profit.name,
                data: profit.profits_monthly.map(amount => { return amount.amount }),
                borderColor: color[i],
                backgroundColor: color[i],
            }
        })
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        xAxes: [{
            ticks: {
                callback: (v, i) => i + 1 <= projectsProfitMonthly.length ? v : null
            }
        }]
        ,
        plugins: {
            datalabels: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };

    useEffect(() => {
        generateLast12Months()
        projectsProfitMonthly.map(() => {
            const color = getRandomColor()
            return setColor(prevState => [...prevState, color])
        })
    }, [])

    return (
        <Box position={'relative'} width={"100%"} height={"300px"}>
            {
                statsLoader
                    ?
                    <LinearProgress />
                    :
                    dataMonthly.length > 0
                    &&
                    <Line data={dataMonthly} options={options} />
            }
        </Box>
    );
};

export default LineLast12Chart;