import { createContext, useState } from "react";

const InvestContext = createContext();

export const InvestProvider = ({ children }) => {
    const [investedAmountInProject, setInvestedAmountInProject] = useState(0);

    return (
        <InvestContext.Provider value={{ investedAmountInProject, setInvestedAmountInProject }}>
            {children}
        </InvestContext.Provider>
    )
}

export default InvestContext