import './App.css';
import RequireAuth from './components/RequireAuth';
import Login from './components/Login';
import CircleModal from './components/CircleModal';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes, Navigate } from 'react-router-dom';
import Investments from './components/Investments';
import Calculator from './components/Calculator';
import MyInvestments from './components/MyInvestments';
import Register from './components/Register';
import Investment from './components/Investments/Investment';
import PrivacyCrowder from './components/Register/PrivacyCrowder';
import UserAgreement from './components/Register/UserAgreement';
import Statistics from './components/Statistics';
import Transactions from './components/Transactions';
import Documents from './components/Documents';
import Profile from './components/Profile';
import AcceptVerification from './components/Profile/AcceptVerification';
import InvestmentContract from './components/Investments/InvestmentContract';
import InvestmentSignius from './components/Investments/InvestmentSignius';
import Notification from './components/Profile/Notifications/Notification';
import Notifications from './components/Profile/Notifications';
import LoaderPage from './components/LoaderPage';
import LoginAs from './components/LoginAs';
import EmailVerify from './components/EmailVerify';
import RegisterEmailVerify from './components/RegisterEmailVerify';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0d4982",
      contrastText: '#fff',
    },
    secondary: {
      main: "#4abdac",
      contrastText: "#fff"
    },
    plain: {
      main: "#fff",
      contrastText: 'rgba(0, 0, 0, 0.5)',
    },
    error: {
      main: "#d32f2f"
    }
  },
});

const iconpopupstyleright = {
  position: 'fixed',
  bottom: 16,
  right: 16,
}

const iconpopupstyleleft = {
  position: 'fixed',
  bottom: 16,
  left: 16,
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/termsofuse' element={<PrivacyCrowder />} />
        <Route path='/useragreement' element={<UserAgreement />} />
        <Route path='/r/:refcode' element={<LoaderPage />} />
        <Route path='/email-verify/' element={<EmailVerify />} />
        <Route path='/register-email-verify/' element={<RegisterEmailVerify />} />
        <Route path='/code-auth/' element={<LoginAs />} />
        <Route element={<RequireAuth />} >
          <Route path='/investments' element={<Investments />} />
          <Route path='/investment/:id' element={<Investment />} />
          <Route path='/investment/:id/contract' element={<InvestmentContract />} />
          <Route path='/transaction/:id' element={<InvestmentSignius />} />
          <Route path='/statistics' element={<Statistics />} />
          <Route path='/myinvestments' element={<MyInvestments />} />
          <Route path='/user/documents' element={<Documents />} />
          <Route path='/transactions' element={<Transactions />} />
          <Route path='/profitcalculator' element={<Calculator top={"200px"} />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/notification/:id' element={<Notification />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/profile/:tab' element={<Profile />} />
          <Route path="/email-verify" element={<AcceptVerification />} />
        </Route>
        <Route path='/*' element={<Navigate to='/investments' />} />
      </Routes>
      <CircleModal icon={<PhoneIcon />} iconpopupstyle={iconpopupstyleright} />
      <CircleModal icon={<ChatBubbleIcon />} iconpopupstyle={iconpopupstyleleft} />
    </ThemeProvider >
  );
}
export default App;