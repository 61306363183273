import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Toolbar,
    Typography,
} from "@mui/material";
import Logo from "../../assets/img/logo_new.png";
import {
    TfiStatsUp,
    TfiHome,
    TfiList,
    TfiFiles,
    TfiReceipt,
    TfiMoney,
} from "react-icons/tfi";
import { IoIosLogIn } from "react-icons/io";
import ListCrowder from "./ListCrowder";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import jwt from 'jwt-decode';
import uuid from 'react-uuid';
import ProfileSettings from "./ProfileSettings";
import Footer from "../Footer";
import "./page.css";
import Notification from "./Notification";

const ButtonStyle = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: 'transparent',
    border: "none",
    fontSize: "14px",
    textTransform: "none",
    padding: "16px 10px 12px 10px",
    '&:hover': {
        color: "rgba(66, 183, 172, 1)",
    }
}

const clickedButtonStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "none",
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "0",
    padding: "16px 10px 12px 10px",
    backgroundColor: "rgba(66, 183, 172, 1)",
    color: "#ffffff",
    '&:hover': {
        backgroundColor: "rgba(66, 183, 172, 1)",
    }
}

const ButtonPStyle = {
    marginTop: "14px",
    fontSize: "12px",
}

const NavbarStyle = {
    backgroundColor: '#fff',
    position: "fixed",
    boxShadow: { xs: "none", md: "1px 0px 7px rgb(0 0 0 / 5%)" },
}

const ButtonsTexts = {
    btns: [
        {
            icon: <TfiHome key={uuid()} size='24px' />,
            pl: <p key={uuid()} style={ButtonPStyle} >INWESTYCJE</p>,
            eng: "investments",
            link: "investments"
        },
        {
            icon: <TfiStatsUp key={uuid()} size='24px' />,
            pl: <p key={uuid()} style={ButtonPStyle} >STATYSTYKI</p>,
            eng: "statistics",
            link: "statistics",
        },
        {
            icon: <TfiList key={uuid()} size='24px' />,
            pl: <p key={uuid()} style={ButtonPStyle} >INWESTUJĘ</p>,
            eng: "myinvestments",
            link: "myinvestments",
        },
        {
            icon: <TfiFiles key={uuid()} size='24px' />,
            pl: <p key={uuid()} style={ButtonPStyle} >DOKUMENTY</p>,
            eng: "documents",
            link: "user/documents",
        },
        {
            icon: <TfiReceipt key={uuid()} size='24px' />,
            pl: <p key={uuid()} style={ButtonPStyle} >TRANSAKCJE</p>,
            eng: "transactions",
            link: "transactions",
        },
        {
            icon: <TfiMoney key={uuid()} size='24px' />,
            pl: <p key={uuid()} style={ButtonPStyle} >KALKULATOR ZYSKU</p>,
            eng: "profitcalculator",
            link: "profitcalculator",
        }
    ],
}

const Navbar = () => {
    const location = useLocation()

    const navigate = useNavigate()

    const { auth } = useAuth()
    const [clicked, setClicked] = useState(0);
    const [isLogin, setIsLogin] = useState(false)
    const [slicedButtonTexts, setSlicedButtonTexts] = useState(ButtonsTexts.btns)

    const handleClick = (i) => {
        setClicked(i)
    }

    useEffect(() => {
        let useToken = localStorage.getItem("token")
        let tokenExpJwt = useToken && jwt(useToken).exp
        let currentDate = new Date()
        let epochTime = currentDate.getTime() / 1000

        if (location.pathname.includes("/investments")) {
            setClicked(0)
        }
        if (location.pathname.includes("/statistics")) {
            setClicked(1)
        }
        if (location.pathname.includes("/myinvestments")) {
            setClicked(2)
        }
        if (location.pathname.includes("/user/documents")) {
            setClicked(3)
        }
        if (location.pathname.includes("/transactions")) {
            setClicked(4)
        }
        if (location.pathname.includes("/profitcalculator")) {
            setClicked(5)
        }
        if (auth?.accessToken || epochTime < tokenExpJwt) {
            setSlicedButtonTexts(ButtonsTexts.btns)
            setIsLogin(true)

        } else {
            setSlicedButtonTexts(ButtonsTexts.btns.slice(0, 1))
            setIsLogin(false)
        }
    }, [auth?.accessToken, location.pathname])

    return (
        <div className="page-container">
            <AppBar sx={NavbarStyle} position="fixed" >
                <Container maxWidth="l">
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "left", lg: "center" },
                                width: "240px",
                                padding: "5px 0 8px 0",
                            }}
                            onClick={() => handleClick(0)}
                        >
                            <Link style={{ textDecoration: "none" }} to={"/investments"} >
                                <Box
                                    component="img"
                                    alt="Crowder"
                                    src={Logo}
                                />
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', lg: 'flex' }
                            }}>
                            {
                                slicedButtonTexts.map((el, i) => {
                                    return (
                                        <Link key={uuid()} style={{ textDecoration: "none" }} to={el.link}>
                                            <Button
                                                className={`${i}`}
                                                onClick={() => handleClick(i)}
                                                key={uuid()}
                                                index={i}
                                                sx={clicked === i ? clickedButtonStyle : ButtonStyle}
                                            >
                                                {el.icon}
                                                {el.pl}
                                            </Button>
                                        </Link>
                                    )
                                })
                            }
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {
                                isLogin
                                    ?
                                    <>
                                        <Notification />
                                        <ProfileSettings />
                                    </>
                                    :
                                    <IconButton onClick={() => { navigate('/login') }} sx={{ borderRadius: 0, padding: '26px 12px', display: 'flex' }}>
                                        <IoIosLogIn color={'rgba(0, 0, 0, 0.5)'} style={{ width: 19, height: 19, marginRight: 10 }} />
                                        <Typography color={'rgba(0, 0, 0, 0.5)'} fontSize={19} >
                                            Zaloguj
                                        </Typography>
                                    </IconButton>
                            }
                            <ListCrowder
                                ButtonsTexts={slicedButtonTexts}
                                clicked={clicked}
                                handleClick={handleClick}
                            />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar >
            <Outlet />
            <Footer />
        </div>
    )
}

export default Navbar;