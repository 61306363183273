import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Grid } from "@mui/material";

const FilterAndSearch = ({
    handleProductChange,
    handleProjectNameChange,
    handleSortByChange,
    handleFilterSubmit,
    sortBy,
    projectName,
    product,
    filterByPeriod,
    handleFilterByPeriodByChange
}) => {

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", verticalAlign: "center", background: "#fff", padding: "20px" }}>
            <Grid container spacing={2} marginRight={2}>
                <Grid item xs={12} md={6} lg={3} >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Długosć inwestycji</InputLabel>
                        <Select
                            label="Długosć inwestycji"
                            value={filterByPeriod}
                            onChange={handleFilterByPeriodByChange}
                        >
                            <MenuItem value={null}>Domyślne</MenuItem>
                            <MenuItem value="6">do 6 miesięcy</MenuItem>
                            <MenuItem value="6and12">6 do 12 miesięcy</MenuItem>
                            <MenuItem value="12and24">12 do 24 miesięcy</MenuItem>
                            <MenuItem value="24">więcej niż 24 miesiące</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3} >
                    <TextField
                        label="Projekt"
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={projectName}
                        onChange={handleProjectNameChange}
                        placeholder="Projekt"
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3} >
                    <FormControl fullWidth >
                        <InputLabel>Produkt</InputLabel>
                        <Select
                            label="Produkt"
                            value={product}
                            onChange={handleProductChange}
                        >
                            <MenuItem value='Domyślne'><em>Domyślne</em></MenuItem>
                            <MenuItem value='crowder_flip_loan'>Pożyczka CROWDER FL!P</MenuItem>
                            <MenuItem value='developer_loan'>Pożyczka developerska</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3} >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Sortowanie</InputLabel>
                        <Select
                            label="Sortowanie"
                            value={sortBy}
                            onChange={handleSortByChange}
                        >
                            <MenuItem value={null}>Domyślne</MenuItem>
                            <MenuItem value="required_amount">Wysokość inwestycji - rosnąco</MenuItem>
                            <MenuItem value="-required_amount">Wysokość inwestycji - malejąco</MenuItem>
                            <MenuItem value="investors_no">Ilość inwestorów - rosnąco</MenuItem>
                            <MenuItem value="-investors_no">Ilość inwestorów - malejąco</MenuItem>
                            <MenuItem value="end_time">Czas do końca - rosnąco</MenuItem>
                            <MenuItem value="-end_time">Czas do końca - malejąco</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" sx={{ height: { xs: "fit-content", lg: "auto" } }} color="primary" onClick={handleFilterSubmit} >
                Filter
            </Button>
        </Box>
    )
}

export default FilterAndSearch;