import useAuth from '../../../../hooks/useAuth';
import useInvest from '../../../../hooks/useInvest';
import { FaMale, FaClock, FaLinkedin } from "react-icons/fa";
import { Box, Typography, LinearProgress, Divider, Grid, Button, TextField, FormHelperText, } from "@mui/material";
import Link from "@mui/material/Link";
import { HiOutlinePrinter } from "react-icons/hi";
import { FaFacebook } from "react-icons/fa";
import { IoIosLogIn } from "react-icons/io";
import { useNavigate, useParams } from 'react-router-dom';
import jwt from 'jwt-decode';

const InvestmentInProgress = ({
    investedAmountFormatted,
    investedPercentage,
    requiredAmountFormatted,
    investorsNo,
    annualYield,
    daysToFinished,
    totalYield,
    ratingLTV,
    scoringRate,
    handlePrint,
    minInvest
}) => {

    const { id } = useParams()
    const shareURL = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.example.com';

    const { auth } = useAuth()
    const navigate = useNavigate()

    const handleShare = () => {
        window.open(shareURL, '_blank', 'width=600,height=400');
    };

    const { investedAmountInProject, setInvestedAmountInProject } = useInvest()

    let useToken = localStorage.getItem("token")
    let tokenExpJwt = useToken && jwt(useToken).exp
    let currentDate = new Date()
    let epochTime = currentDate.getTime() / 1000

    return (
        <Box sx={{ background: "#fff", position: "relative", padding: "20px", }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
                <Box className="spacer"></Box>
                <Typography color={"rgba(0, 0, 0, 0.5)"} textAlign={"center"}  >
                    Inwestycja w trakcie finansowania
                </Typography>
                <Box >
                    <Typography color={"primary"} textAlign={"center"} fontSize={10} fontWeight={700} >
                        RATING
                    </Typography>
                    <Box sx={{ background: "#0bb6ad", borderRadius: "4px", padding: "4px 14px" }}>
                        <Typography textAlign={"center"} color={"#ffff00"} fontSize={24} fontWeight={700}>{scoringRate}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography color="primary" fontSize={14} sx={{ paddingRight: "50px" }} >
                    {investedAmountFormatted} PLN ({investedPercentage}%)
                </Typography>
                <Typography color="primary">
                    {requiredAmountFormatted} PLN
                </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
                <LinearProgress sx={{ height: 10 }} variant="determinate" value={parseFloat(investedPercentage)} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}>
                <Box sx={{ width: "48%" }}>
                    <Box sx={{ display: "flex" }}>
                        <FaMale sx={{ marginRight: "5px" }} color='#0d4982' />
                        <Typography fontSize={11} color="primary"><span style={{ fontWeight: "bold" }}>{investorsNo}</span> INWESTORÓW</Typography>
                    </Box>
                    <Divider sx={{ margin: "8px 0", borderBottom: "1px solid #0d4982" }} />
                    <Typography color="primary" fontSize={21} fontWeight={700} textAlign={"center"}>
                        {annualYield}%
                    </Typography>
                    <Typography color="primary" fontSize={11} textAlign={"center"} >
                        ROCZNA STOPA ZWROTU
                    </Typography>
                    <Divider sx={{ margin: "8px 0", borderBottom: "1px solid #0d4982" }} />
                </Box>
                <Box sx={{ width: "48%" }}>
                    <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                        <Typography fontSize={11} sx={{ marginRight: "5px" }} textAlign={"end"}>
                            {daysToFinished < 1 ? "CZAS UPŁYNĄŁ" : `POZOSTAŁO ${daysToFinished} DNI`}
                        </Typography>
                        <FaClock color='#0d4982' fontSize={16} />
                    </Box>
                    <Divider sx={{ margin: "8px 0", borderBottom: "1px solid #0d4982" }} />
                    <Typography color="primary" fontSize={21} fontWeight={700} textAlign={"center"}>
                        {totalYield}%
                    </Typography>
                    <Typography color="primary" fontSize={11} textAlign={"center"} >
                        ZYSK CAŁKOWITY
                    </Typography>
                    <Divider sx={{ margin: "8px 0", borderBottom: "1px solid #0d4982" }} />
                </Box>
            </Box>
            <Typography color="primary" fontSize={11} textAlign={"center"} marginBottom={2}>
                Czas inwestycji: 0 miesięcy, Wskaźnik LTV: {ratingLTV}%
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                <Button color="secondary" variant="contained" onClick={handlePrint} sx={{ marginRight: "8px" }}>
                    <HiOutlinePrinter fontSize={12} />
                    <Typography fontSize={10} marginLeft={1} >Drukuj</Typography>
                </Button>
                <Button color="primary" variant="contained" onClick={handleShare} sx={{ marginRight: "8px" }}>
                    <FaFacebook fontSize={12} />
                    <Typography fontSize={10} marginLeft={1} >Udostępnij</Typography>
                </Button>
                <Button color="primary" variant="contained" onClick={handleShare}>
                    <FaLinkedin fontSize={12} />
                    <Typography fontSize={10} marginLeft={1} >Udostępnij</Typography>
                </Button>
            </Box>
            <Grid container spacing={0} marginBottom={1}>
                <Grid item xs={8} >
                    <TextField
                        type="number"
                        value={investedAmountInProject}
                        onChange={(e) => setInvestedAmountInProject(e.target.value)}
                        style={{ background: "#EDF9F7", border: "1px solid #4abdac", width: "100%" }}
                        size="small"
                    />

                </Grid>
                <Grid item xs={4} >
                    <TextField
                        disabled
                        value={".00 PLN"}
                        style={{ background: "#EDF9F7", border: "1px solid #4abdac" }}
                        size="small"
                    />
                </Grid>
                {minInvest > investedAmountInProject && <FormHelperText error fontWeight={700}>Minimalna kwota inwestycji to {minInvest} PLN</FormHelperText>}
            </Grid>
            {
                auth?.accessToken || epochTime < tokenExpJwt
                    ?
                    <Button type="submit" color='secondary' variant="contained" fullWidth onClick={() => minInvest <= investedAmountInProject && navigate(`/investment/${id}/contract`)} >Pożyczam</Button>
                    :
                    <>
                        <Button onClick={() => navigate("/login")} color='secondary' variant="contained" fullWidth sx={{ background: "#4abdac", color: "#fff" }}>
                            <IoIosLogIn fontSize={16} style={{ marginRight: "10px" }} />
                            Zaloguj się aby zainwestować
                        </Button>
                        <Grid container style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                            <Grid item >
                                <Link href="/register" variant="body2">
                                    Nie masz konta? Załóż je!
                                </Link>
                            </Grid>
                        </Grid>
                    </>
            }
        </Box>
    )
}

export default InvestmentInProgress