import { useEffect, useState } from "react";
import { Box, Avatar, Typography, IconButton, Popover, Divider } from "@mui/material";
import { TfiPanel, TfiPowerOff } from "react-icons/tfi";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ProfileSettings = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [username, setUsername] = useState("")
    const [useremail, setUseremail] = useState("")
    const [avatarImg, setAvatarImg] = useState()
    const { setAuth } = useAuth()
    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        setAuth('')
        localStorage.removeItem('token')
        localStorage.removeItem('tokenOtp')
        localStorage.removeItem('access')
        localStorage.removeItem('codeReset')
        localStorage.removeItem('refresh')
        navigate('/investments')
        window.location.reload();
    }

    const handleProfile = (event) => {
        handleClose()
        navigate("/profile")
    }

    const open = Boolean(anchorEl)
    const id = open ? 'avatar-popover' : undefined

    useEffect(() => {
        axios("/users/profile/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setUsername(res.data.name)
                setUseremail(res.data.email)
                if (res.data.avatar !== null) {
                    setAvatarImg(res.data.avatar.original)
                }
            })
            .catch(err => {
                console.error(err)
            }
            )
    }, [])

    return (
        <Box sx={{ flexGrow: 0, display: 'flex' }}>
            <IconButton aria-describedby={id} onClick={handleClick} sx={{ p: 0 }}>
                <Avatar src={avatarImg} alt="" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ marginTop: "20px" }}
            >
                <Box sx={{ display: 'flex', padding: '16px' }}>
                    <Avatar src={avatarImg} sx={{ width: 80, height: 80 }} alt="" />
                    <Box >
                        <Typography fontSize={14} variant="body1" gutterLeft sx={{ marginLeft: "10px" }}>
                            {username}
                        </Typography>
                        <Typography fontSize={10} variant="body1" gutterLeft sx={{ marginLeft: "10px" }}>
                            {useremail}
                        </Typography>
                    </Box>
                </Box>
                <IconButton onClick={handleProfile} sx={{ display: 'flex', justifyContent: "left", padding: '8px 16px', width: "100%", borderRadius: "0" }}>
                    <TfiPanel />
                    <Typography fontSize={14} variant="body1" gutterLeft sx={{ marginLeft: "10px" }} >
                        Mój Profil
                    </Typography>
                </IconButton>
                <Divider />
                <IconButton onClick={logout} sx={{ display: 'flex', padding: '8px 16px', justifyContent: "left", borderRadius: "0", width: "100%" }}>
                    <TfiPowerOff />
                    <Typography fontSize={14} variant="body1" sx={{ marginLeft: "10px" }}>
                        Wyloguj
                    </Typography>
                </IconButton>
            </Popover>
        </Box >
    )
}

export default ProfileSettings;