
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container, Alert } from "@mui/material";
import bgImage from "../../../assets/img/bg-sign-up.png";
import Logo from "../../../assets/img/logo_new.png";
import IconButton from '@mui/material/IconButton';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialLinkedin } from 'reactjs-social-login';

const styles = {

    largeIcon: {
        width: 60,
        height: 60,
    },

};

const LoginForm = ({
    handleSubmit,
    loginMessage,
    resetPassword,
    onLoginStart,
    onLogoutSuccess,
    onResolve,
}) => {

    const { REACT_APP_GG_APP_ID, REACT_APP_FB_APP_ID, REACT_APP_LINKEDIN_APP_ID, REACT_APP_LINKEDIN_APP_SECRET } = process.env

    return (
        <Container
            sx={{
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                WebkitBackgroundSize: "cover",
                width: "100%",
                maxWidth: "none"
            }}
            style={{
                maxWidth: "none",
                padding: "0px"
            }}
        >
            <Box>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        sx={{ background: "#ffffff", height: "100vh", width: "100%" }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "left",
                                height: "70px",
                                margin: "48px 32px"
                            }}
                        >
                            <Box
                                component="img"
                                alt="Crowder"
                                src={Logo}
                            />
                        </Box>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "left",
                            }}
                        >
                            <Typography component="h1" variant="h3">
                                Logowanie
                            </Typography>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Hasło"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <Grid container sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Grid item >
                                        <Link onClick={resetPassword} sx={{ cursor: "pointer" }} variant="body2">
                                            Zapomniałeś hasła?
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Zaloguj
                                </Button>
                                {
                                    loginMessage &&
                                    <Alert sx={{ mb: 4 }} severity="error"> {loginMessage}</Alert>
                                }
                                <Grid container style={{ marginBottom: "16px", display: "flex", justifyContent: "center" }}>
                                    <Grid item >
                                        <Link href="register" variant="body2">
                                            Nie masz konta? Załóż je!
                                        </Link>
                                        <Typography>
                                            Lub Zaloguj się przez:
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                                    {/* <IconButton color="primary" style={styles.largeIcon} >
                                        <LoginSocialGoogle
                                            provider="google"
                                            client_id={REACT_APP_GG_APP_ID || ''}
                                            scope="openid profile email"
                                            discoveryDocs="claims_supported"
                                            onLoginStart={onLoginStart}
                                            onResolve={onResolve}
                                            onReject={err => {
                                                console.error(err);
                                            }}
                                        >
                                            <GoogleIcon style={styles.largeIcon} />
                                        </LoginSocialGoogle>
                                    </IconButton> */}
                                    <IconButton color="primary" style={styles.largeIcon} >
                                        <LoginSocialFacebook
                                            provider={'facebook'}
                                            appId={REACT_APP_FB_APP_ID || ''}
                                            fieldsProfile={
                                                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                            }
                                            onLoginStart={onLoginStart}
                                            onLogoutSuccess={onLogoutSuccess}
                                            onResolve={onResolve}
                                            onReject={err => {
                                                console.error(err);
                                            }}
                                        >
                                            <FacebookIcon style={styles.largeIcon} />
                                        </LoginSocialFacebook>
                                    </IconButton>
                                    <IconButton color="primary" style={styles.largeIcon} >
                                        <LoginSocialLinkedin
                                            provider={'linkedin'}
                                            client_id={REACT_APP_LINKEDIN_APP_ID || ''}
                                            client_secret={REACT_APP_LINKEDIN_APP_SECRET || ''}
                                            redirect_uri={'https://crowder.merope.pl/login'}
                                            onResolve={onResolve}
                                            onReject={err => {
                                                console.error(err);
                                            }}
                                        >
                                            <LinkedInIcon style={styles.largeIcon} />
                                        </LoginSocialLinkedin>
                                    </IconButton>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default LoginForm