import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const CustomArrow = ({ isArrowRight, onClick }) => {

    const arrowStylePrev = {
        position: "absolute",
        width: "30px",
        height: "30px",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%) ",
        zIndex: 999,
        cursor: "pointer",
        color: "#0d4982",
    }

    const arrowStyleNext = {
        position: "absolute",
        width: "30px",
        height: "30px",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 999,
        cursor: "pointer",
        color: "#0d4982",
    }

    return (
        <div>
            {
                isArrowRight
                    ?
                    <FaChevronRight color='primary' style={arrowStyleNext} onClick={onClick} />
                    :
                    <FaChevronLeft color='primary' style={arrowStylePrev} onClick={onClick} />

            }
        </div>
    )
}

export default CustomArrow