import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import uuid from 'react-uuid';
const formatPrice = (priceIn) => {
    let złotówki = Math.floor(priceIn / 100);
    let grosze = priceIn % 100;

    let złotówkiZSpacjami = złotówki.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return złotówkiZSpacjami + (grosze !== 0 ? '.' + (grosze < 10 ? '0' : '') + grosze : '.00');
}

const TableRows = ({ title, status, investedAmount, totalYield, foundsToBeWithdrawn, endDate }) => {

    const date = new Date(endDate)
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    return (
        <TableRow key={uuid()}>
            <TableCell>{title}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{formatPrice(investedAmount)} PLN</TableCell>
            <TableCell>{totalYield} %</TableCell>
            <TableCell>{foundsToBeWithdrawn}zł</TableCell>
            <TableCell>{day}.{month}.{year}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

export default TableRows