import { Typography } from "@mui/material"


const Documentation = () => {
    return (
        <>
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={21}>Dokumenty do pobrania</Typography>
            <Typography color="rgba(0, 0, 0, 0.5)" fontSize={21}>Dokumentacja</Typography>
        </>
    )
}

export default Documentation