import { Box, Button, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SliderShow from '../../SliderShow'
import axios from 'axios'
import useInvest from '../../../hooks/useInvest';
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid';

const monthDiff = (d1, d2) => {
    let months;
    const newD1 = new Date(d1)
    const newD2 = new Date(d2)
    months = (newD2.getFullYear() - newD1.getFullYear()) * 12;
    months -= newD1.getMonth();
    months += newD2.getMonth();
    return months <= 0 ? 0 : months;
}

const InvestmentContract = () => {

    const { id } = useParams()

    const navigate = useNavigate()

    const { investedAmountInProject } = useInvest()

    const [images, setImages] = useState([])
    const [loanStartDate, setLoanStartDate] = useState()
    const [loanPaidDate, setLoanPaidDate] = useState()
    const [annualYield, setAnnualYield] = useState(27.00)
    const [totalYield, setTotalYield] = useState(35.50)
    const [loanPaidLength, setLoanPaidLength] = useState()
    const [dividendPaymentDates, setDividendPaymentDates] = useState([])

    const handleSign = () => {
        axios.post("/investments/invest/", {
            "project_id": id,
            "invested_amount": investedAmountInProject
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
        )
            .then((res) => {
                navigate(`/transaction/${id}`)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const getProject = async () => {
        axios.get(`/projects/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            res.data.pictures !== null && res.data.pictures.map(el => {
                return setImages(prevState => [...prevState, el.picture.original])
            });
            setLoanPaidDate(res.data.end_time)
            setAnnualYield(res.data.annual_yield)
            setTotalYield(res.data.total_yield)
            setLoanStartDate(res.data.loan_start_date)
            setLoanPaidDate(res.data.loan_paid_date)
            setLoanPaidLength(monthDiff(res.data.loan_start_date, res.data.loan_paid_date) + 1)
            setDividendPaymentDates(res.data.dividend_payment_dates)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getProject()
    }, [])

    return (
        <Container maxWidth="lg" sx={{ py: "20px", marginTop: "150px", background: "#fff", marginBottom: "60px" }}>
            <SliderShow images={images} />
            <Box display={'flex'} justifyContent={'space-around'} marginBottom={3} flexDirection={{ xs: "column", md: "row" }}>
                <Box>
                    <Typography>Roczna stopa zwrotu</Typography>
                    <Typography fontSize={24} marginBottom={2}>{annualYield}%</Typography>
                    <Typography>Zainwestowane środki w projekt</Typography>
                    <Typography fontSize={24} marginBottom={{ xs: 2, md: 0 }}>{investedAmountInProject}.00 PLN</Typography>
                </Box>
                <Box>
                    <Typography>Zysk całkowity</Typography>
                    <Typography fontSize={24} marginBottom={2}>{totalYield}%</Typography>
                    <Typography>Środki do wypłaty z projektu</Typography>
                    <Typography fontSize={24} marginBottom={{ xs: 2, md: 0 }}>{investedAmountInProject}.00 PLN</Typography>
                </Box>
                <Box>
                    <Typography>Długość pożyczki</Typography>
                    <Typography fontSize={24} marginBottom={2}>{loanPaidLength} m-cy</Typography>
                    <Typography>Termin zapadalności pożyczki</Typography>
                    <Typography fontSize={24} marginBottom={{ xs: 2, md: 0 }}>{loanPaidDate}</Typography>
                </Box>
            </Box>
            <Box>
                <Typography fontSize={24} fontWeight={600}>Daty i kwoty oprocentowania</Typography>
                {
                    dividendPaymentDates.map(el => {
                        return <Typography key={uuid()}>{el.date}</Typography>
                    })
                }
            </Box>
            <Box display={'flex'} justifyContent={"right"}>
                <Box>
                    <Button variant='contained' color='secondary' onClick={handleSign}>Podpisuje</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default InvestmentContract