import axios from "axios";
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    const refreshToken = async () => {
        try {
            const response = await axios.post('/token/refresh/',
                {
                    "refresh": localStorage.getItem("refresh"),
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            setAuth((prevUser) => ({ ...prevUser, accessToken: response.data.access }));
            localStorage.setItem("token", response.data.access)
        } catch (error) {
            console.error(error);
            setAuth(null);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            refreshToken();
        }, 15 * 60 * 1000);

        return () => clearInterval(interval);
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;