import { useEffect, useState } from "react";
import { Box, Popover, Divider, Badge, Typography, List, ListItem, Button } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import uuid from "react-uuid";

const Notification = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subjects, setSubjects] = useState([])
    const navigate = useNavigate()

    const handleMessage = (id) => {
        navigate(`/notification/${id}`)
    }

    const handleNotify = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl)
    const id = open ? 'avatar-popover' : undefined

    useEffect(() => {
        axios("/notify/mail-history/", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                setSubjects(res.data.results)
            })
            .catch(err => console.log("err"))
    }, [])

    return (
        <Box sx={{ flexGrow: 0, display: 'flex' }}>
            <Box display={'flex'} alignItems={'center'}>
                <Badge variant="dot" color="primary" onClick={handleNotify} sx={{ marginRight: "16px", cursor: "pointer" }}>
                    <MailIcon color="action" size={'big'} />
                </Badge>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ marginTop: "20px" }}
            >
                <Box sx={{ display: 'flex', padding: '16px', width: "300px" }}>
                    <Typography> Powiadomienia </Typography>
                </Box>
                <Divider />
                <Box>
                    <List >
                        {
                            subjects.map(subject => {
                                return (
                                    <Box key={uuid()}>
                                        <ListItem onClick={() => handleMessage(subject.id)} >
                                            <Typography >{subject.subject}</Typography>
                                        </ListItem>
                                        <Divider />
                                    </Box>
                                )
                            })
                        }
                    </List>
                    <Button>Zobacz wszystkie</Button>
                </Box>
            </Popover>
        </Box >
    )
}

export default Notification;