import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import uuid from 'react-uuid';

const ButtonMobileStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: 'transparent',
    border: "none",
    fontSize: "14px",
    textTransform: "none",
    paddingBottom: "0px",
    width: "100%",
    cursor: "pointer",
    color: "#0d4982",
    '&:hover': {
        color: "rgba(66, 183, 172, 1)",
    }
}

const clickedButtonMobileStyle = {
    display: "flex",
    alignItems: "center",
    border: "none",
    fontSize: "14px",
    textTransform: "none",
    paddingBottom: "0px",
    backgroundColor: "rgba(66, 183, 172, 1)",
    color: "#ffffff",
    cursor: "pointer",
    '&:hover': {
        backgroundColor: "rgba(66, 183, 172, 1)",
    }
}

const ListCrowder = ({ ButtonsTexts, clicked, handleClick }) => {

    const [drawerState, setDrawerState] = useState(false);

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });

    };

    const list = (anchor) => (
        <Box
            sx={{ paddingTop: "90px" }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List >
                {
                    ButtonsTexts.map((el, i) => {
                        return (
                            <Link key={uuid()} style={{ textDecoration: "none" }} to={el.link}>
                                <ListItem
                                    key={uuid()}
                                    disablePadding
                                    sx={clicked === i ? clickedButtonMobileStyle : ButtonMobileStyle}
                                >
                                    <ListItemButton
                                        key={uuid()}
                                        onClick={e => handleClick(i, e)}
                                        color="primary"
                                    >
                                        {el.icon}
                                        <ListItemText
                                            key={uuid()}
                                            sx={{ marginLeft: "15px" }}
                                            primary={el.pl}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        )
                    })
                }
            </List>
        </Box>
    );

    return (
        <>
            <Box sx={{
                flexGrow: "1",
                display: { xs: 'flex', lg: 'none' },
                justifyContent: "flex-end",
                padding: '26px 0'
            }}
            >
                <IconButton
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer("top", !drawerState["top"])}
                    color="#67757c"
                >
                    <MenuIcon />
                </IconButton>
            </Box>
            <Drawer
                sx={{ position: "absolute", top: "90px", paddingTop: "90px", zIndex: 1000 }}
                anchor={"top"}
                open={drawerState["top"]}
                onClose={toggleDrawer("top", false)}
            >
                {list("top")}
            </Drawer>
        </>
    );
}

export default ListCrowder;