import { Box, CircularProgress } from "@mui/material"
import axios from "axios"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"


const LoaderPage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { refcode } = params

    useEffect(() => {
        axios("/referral/ref-code/", {
            params: {
                code: refcode
            }
        })
            .then(res => {
                navigate(`/investment/${refcode}`)
            })
            .catch(err => {
                navigate("/investments")
            })
    }, [])

    return (
        <Box sx={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress color="secondary" size={200} sx={{ borderRadius: "20px", }} />
        </Box>
    )
}

export default LoaderPage