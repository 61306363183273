import { Box, Button, Container, Typography } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import SigniusLogo from "../../../assets/img/signius_logo_rgb.svg"


const InvestmentSignius = () => {
    const { id } = useParams()

    const [projectName, setProjectName] = useState("")

    const getProject = async () => {
        axios.get(`/projects/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            setProjectName(res.data.name)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getProject()
    }, [])

    return (
        <Container maxWidth="lg" sx={{ marginTop: "150px", background: "#fff", textAlign: "center", marginBottom: "50px" }}>
            <Box sx={{ padding: "30px 20px 0px 20px" }}>
                <Typography fontSize={26} marginBottom={5} fontWeight={700}>
                    Dokończ inwestowanie w projekt {projectName}, podpisz dokumenty w serwisie signius.eu
                </Typography>
            </Box>
            <Box sx={{ padding: "0px 100px 30px 100px" }}>
                <Typography marginBottom={3}>
                    Na twój adres e-mail został wysłany link do serwisu Signius.eu, sprawdź pocztę i podpisz dokumenty w serwisie Signius.eu.
                </Typography>
                <Typography marginBottom={3}>
                    Jeśli nie korzystałeś z Signius.eu, konieczne będzie założenie konta i przejście procesu weryfikacji. Przygotuj Dowód Osobisty i telefon, będą one konieczne do wideoweryfikacji.
                </Typography>
                <Typography marginBottom={3}>
                    Jeśli nie korzystałeś z Signius.eu, konieczne będzie założenie konta i przejście procesu weryfikacji. Przygotuj Dowód Osobisty i telefon, będą one konieczne do wideoweryfikacji. Wideoweryfikacja przeprowadzana jest w dni robocze od godziny 8 do 20.
                </Typography>
                <Typography marginBottom={3}>
                    Weryfikacja tożsamości wynika  m.in.  z wytycznych Komisji Nadzoru Finansowego (KNF).
                </Typography>
                <Typography marginBottom={3}>
                    Kwota Twojej wpłaty uwzględniona zostanie w kwocie zbiórki po podpisaniu przez Ciebie Umowy.
                </Typography>
                <Typography marginBottom={1}>
                    Podpisałeś już dokumenty?
                </Typography>
                <Button variant="contained" sx={{ marginBottom: 3 }} onClick={() => window.location.href = 'https://professional.signius.eu/#/login'}>
                    SPRAWDŹ PODPIS
                </Button>
                <Typography marginBottom={1}>
                    Masz już konto w serwisie signius.eu?
                </Typography>
                <Button variant="contained" onClick={() => window.location.href = 'https://professional.signius.eu/#/login'}>
                    Przejdź do signius.eu i podpisz dokumenty
                </Button>
                <Box marginTop={4}>
                    <img src={SigniusLogo} alt="signius" style={{ maxWidth: "300px", width: "100%" }} />
                </Box>
            </Box>
        </Container>
    )
}

export default InvestmentSignius