import { Card, Tooltip, CardMedia, CardContent, CardActions, Typography, Button, Box, Divider, LinearProgress, tooltipClasses, IconButton, } from '@mui/material';
import CardImgDefault from "../../../assets/img/cardimg.jpg";
import { Link } from 'react-router-dom';
import { FaMale, FaClock } from "react-icons/fa";
import RatingArr from './RatingArr';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const formatPrice = (price) => {
    price = price / 100
    return parseFloat(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
}

const dateDiffsDays = (date) => {
    const currentData = new Date();
    const endDate = new Date(date)
    const diffDate = endDate - currentData
    return Math.floor(diffDate / (1000 * 60 * 60 * 24));
}

const dateDiffs = (date1, date2) => {
    const diffDate1 = new Date(date1)
    const diffDate2 = new Date(date2)
    const year1 = diffDate1.getFullYear();
    const month1 = diffDate1.getMonth();
    const year2 = diffDate2.getFullYear();
    const month2 = diffDate2.getMonth();
    return (year2 - year1) * 12 + (month2 - month1);
}

const InvestmentCard = ({
    productType,
    title,
    scoringRate,
    id,
    CardImg,
    location,
    investedAmount,
    requiredAmount,
    investedPercentage,
    annualYield,
    investorsNo,
    totalYield,
    loanPaidDate,
    loanStartDate,
    variableInterestVisible,
    status
}) => {

    const [isHover, setIsHover] = useState(false)

    const cardImageAppear = {
        transition: "all .4s",
        transform: isHover ? "scale(1.2)" : "scale(1)"
    }

    const requiredAmountFormatted = formatPrice(requiredAmount)
    const investedAmountFormatted = formatPrice(investedAmount)
    const daysToFinished = dateDiffsDays(loanPaidDate)

    const cardButtonRendered = (status) => {
        switch (status) {
            case "pending_verification":
                return (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ borderRadius: 0 }}
                        disabled
                    >
                        Oczekująca
                    </Button>
                )
            case "open":
                return (
                    <Link to={`/investment/${id}`} style={{ width: "100%" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ borderRadius: 0 }}
                        >
                            Pożyczam
                        </Button>
                    </Link>
                )
            case "success":
                return (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ borderRadius: 0 }}
                        disabled
                    >
                        Zakończone
                    </Button>
                )
            default:
                return (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ borderRadius: 0 }}
                        disabled
                    >
                        Oczekująca
                    </Button>
                )
        }
    }

    return (
        <Card sx={{ maxWidth: { xs: 320, lg: 345 } }}>
            <CardContent sx={{ display: "flex", justifyContent: "space-between", p: "10px 16px", background: "rgba(66, 183, 172, 1)", color: "#ffffff" }}>
                <Box>
                    <Typography sx={{ fontSize: 9 }} gutterBottom>
                        {
                            productType === "developer_loan"
                                ?
                                productType.replace("developer_loan", "Pożyczka developerska")
                                :
                                productType.replace("crowder_flip_loan", "Pożyczka CROWDER FLIP")
                        }
                    </Typography>
                    <Typography variant="h3" sx={{ fontSize: 16, fontWeight: 600 }} gutterBottom>
                        {title}
                    </Typography>
                    <Typography sx={{ fontSize: 10, maxWidth: 150 }} >
                        {location}
                    </Typography>
                </Box>
                <LightTooltip title={<RatingArr />} >
                    <Box sx={{ color: "yellow" }}>
                        <Typography textAlign={"center"} sx={{ fontSize: 10 }} >
                            RATING
                        </Typography>
                        <Typography fontSize={32} fontWeight={700}>
                            {scoringRate}
                        </Typography>
                    </Box>
                </LightTooltip>
            </CardContent>
            <Box sx={{ position: "relative", overflow: 'hidden' }}>
                <Link to={`/investment/${id}`} style={{ width: "100%" }}>
                    <Box
                        onClick={() => console.log("karta")}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        sx={{ cursor: "pointer" }}
                        style={cardImageAppear}
                    >
                        <CardMedia
                            component="img"
                            height="194"
                            image={CardImg ? CardImg : CardImgDefault}
                            alt=""
                        />
                    </Box>
                </Link>
                <Box
                    sx={{
                        padding: "5px 15px",
                        backgroundColor: 'rgba(0, 81, 155, 0.9)',
                        position: 'absolute',
                        top: '50%',
                        right: '0',
                        transform: 'translateY(-50%)',
                    }}
                >
                    <Typography sx={{ fontSize: "14px", color: "#ffff00", textAlign: "center" }}>{variableInterestVisible ? "ZYSK MIN." : "ZYSK DO"}</Typography>
                    <Typography sx={{ fontSize: "24px", color: "#ffff00", fontWeight: "800", textAlign: "center" }}>{totalYield}%</Typography>
                    <Typography sx={{ fontSize: "12px", color: "#ffffff", fontWeight: "800", textAlign: "center" }}>
                        Za {dateDiffs(loanStartDate, loanPaidDate)} miesięcy
                    </Typography>
                </Box>
            </Box>
            <CardContent>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography color="primary" sx={{ fontSize: 14 }}>
                        {investedAmountFormatted} PLN ({investedPercentage}%)
                    </Typography>
                    <Typography color="primary">
                        {requiredAmountFormatted} PLN
                    </Typography>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={parseFloat(investedPercentage) < 100 ? parseFloat(investedPercentage) : 100} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}>
                    <Box sx={{ display: "flex" }}>
                        <FaMale sx={{ marginRight: "5px" }} color='#0d4982' />
                        <Typography fontSize={11} color="primary"><span style={{ fontWeight: "bold" }}>{investorsNo}</span> INWESTORÓW</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography fontSize={11} sx={{ display: "flex", alignItems: "flex-start", marginRight: "5px" }}>{daysToFinished < 1 ? "CZAS UPŁYNĄŁ" : `POZOSTAŁO ${daysToFinished} DNI`}</Typography>
                        <FaClock color='#0d4982' fontSize={16} />
                    </Box>
                </Box>
                <Divider sx={{ margin: "8px 0", borderBottom: "1px solid #0d4982" }} />
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }}>
                    <Typography color="primary" fontSize={21} fontWeight={700}>
                        {annualYield}%
                    </Typography>
                    <Typography color="primary">
                        ROCZNA STOPA ZWROTU
                    </Typography>
                </Box>
                <Divider sx={{ margin: "8px 0", borderBottom: "1px solid #0d4982" }} />
            </CardContent>
            <CardActions disableSpacing sx={{ p: 0 }}>
                {cardButtonRendered(status)}
            </CardActions>

        </Card>
    );
}

export default InvestmentCard;