import { Box } from "@mui/material";
import "./RatingArr.css";

const RatingArr = () => {
    return (
        <Box sx={{ background: "#fff", width: "100%" }}>
            <div className='scoring scoring-A'>AAA</div>
            <div className='scoring scoring-A'>AA</div>
            <div className='scoring scoring-A'>A</div>
            <div className='scoring scoring-B'>BBB</div>
            <div className='scoring scoring-B'>BB</div>
            <div className='scoring scoring-B'>B</div>
            <div className='scoring scoring-C'>CCC</div>
            <div className='scoring scoring-C'>CC</div>
            <div className='scoring scoring-C'>C</div>
        </Box>
    )
}

export default RatingArr