import { Container, Paper, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import bgImage from "../../../assets/img/login-register.jpg";

const PrivacyCrowder = () => {
    return (
        <Container
            sx={{
                display: "flex",
                justifyContent: "center",
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                WebkitBackgroundSize: "cover",
                backgroundAttachment: "fixed",
                width: "100%",
                minHeight: "100vh"
            }}
            style={{
                maxWidth: "none",
                padding: "0px"
            }}
        >
            <Paper elevation={0} sx={{ width: "90%", margin: "5% 0", boxShadow: "1px 0 20px rgb(0 0 0 / 8%)" }}>
                <Typography sx={{ display: "flex", color: "#fff", justifyContent: "center", background: "#00519b", fontSize: "32px", padding: "12px 20px" }} > WARUNKI UŻYTKOWANIA</Typography>
                <Box fontSize={13} sx={{ padding: "24px" }}>
                    <Typography fontSize={13} marginBottom={5}>
                        Polityka Prywatności - Crowder.Pro
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Niniejsza polityka prywatności (zwana dalej "Polityką Prywatności") opisujące w jaki sposób i w jakim celu przetwarzamy dane osobowe klientów i użytkowników platformy crowdfoundingowej Crowder.Pro, funkcjonującej pod adresem: <Link to="https://www.crowder.pro/" target='_blank'>https://www.crowder.pro/</Link>
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        1. Działalność Platformy
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Platforma Crowder.Pro to platforma crowdfundingu inwestycyjnego dostarczająca rozwiązania organizatorom projektów w celu pozyskania finansowania do przedsięwzięć w sektorach nowoczesnej gospodarki. Dzięki działalności Platformy użytkownicy mają dostęp do ofert crowdfoundingowych (na zasadzie udziałowej oraz pożyczkowej), w ramach których pozyskiwany jest kapitał na realizacje projektów inwestycyjnych („Projekty”).
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        W zamian za zaangażowane środki Klient otrzymuje instrumenty udziałowe spółki realizującej Projekt, lub też oprocentowani z pożyczki, której udziela tej spółce.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Szczegółowe informacje na temat praw i obowiązków Użytkowników oraz modelu Kampanii, jak również definicje niektórych terminów pisanych wielką literą w treści niniejszej Polityki, określa Regulamin platformy Crowder.Pro, z którym można zapoznać się pod linkiem /hiperłącze/.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        2. Zastosowanie Polityki Prywatności
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Polityka Prywatności ma zastosowanie do każdej osoby, która korzysta z platformy Crowder.Pro, jak również do innych osób, których dane osobowe są przetwarzane w związku z rejestracją Użytkownika, zawieraniem lub wykonywaniem umów w ramach finansowania Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Polityka Prywatności ma zastosowanie wyłącznie do danych osobowych przetwarzanych przez Crowder.Pro, nie zaś do stron internetowych lub usług osób trzecich, do których prowadzą łącza na Platformie Crowder.Pro – w tym w szczególności łącza do stron właścicieli Projektów. W związku z powyższym nie ponosimy odpowiedzialności za zawartość lub praktyki w zakresie prywatności i bezpieczeństwa oraz polityki prywatności jakichkolwiek stron lub usług osób trzecich, do których łącza znajdują się na Platformie Crowder.Pro.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Przez dane osobowe rozumie się wszelkie informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej będącej użytkownikiem Portalu Crowder.Pro, lub przedstawicielem takiego użytkownika.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Polityka wypełnia treść postanowień rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE („Rozporządzenie RODO”).
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        3. Administrator danych osobowych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Administratorem danych osobowych użytkowników Platformy Crowder.Pro jest. Crowder Spółka z ograniczoną odpowiedzialnością z siedzibą w Białymstoku (15-540), przy ul. Żurawiej 71, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy w Białymstoku, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000855701, NIP: 9662142494, REGON: 386802855, posiadającą kapitał zakładowy w wysokości 205 000,00 zł
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Wszelkie uwagi związane z ochroną danych osobowych użytkownicy mogą zgłaszać pod adresem e-mail: [--]
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        4. Podstawa prawna
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        W związku z faktem, iż przetwarzanie danych jest niezbędne dla zawarcia i wykonywania umów związanych z finansowaniem Projektów oraz korzystaniem z Platformy Crowder.Pro, a brak tych danych oznaczałby brak możliwości korzystania z platformy, podstawą prawną przetwarzania danych jest art. 6 ust. 1 (b) Rozporządzenia RODO.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        W przypadku wyrażenia zgody na przetwarzanie danych w celach marketingowych (art. 6 ust. 1 (a) Rozporządzenia RODO) dane osobowe mogą być przetwarzane z wykorzystaniem poczty email, komunikatorów internetowych, telefonicznej, wiadomości SMS oraz korespondencji pisemnej.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        W ramach prawnie uzasadnionych interesów Crowder.Pro, takich jak chociażby konieczność informowania o Projektach, potrzeba podnoszenia jakości oraz rozwoju usług, konieczność zapewnienia bezpieczeństwa użytkowników, zwalczania oszustw i nadużyć lub jeżeli jest to konieczne do ustalenia i ochrony naszych praw lub dochodzenia roszczeń przetwarzanie danych może odbywać się na podsatwie art. 6 ust. 1 (f) Rozporządzenia RODO.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Przetwarzamy także dane osobowe na podstawie i w ramach obowiązków wynikających z obowiązujących przepisów prawa w szczególności w świetle obowiązujących przepisów dotyczących przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu,
                    </Typography>
                    <Typography fontSize={13} marginBottom={3} marginLeft={3}>
                        5. Cel przetwarzania danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Użytkownik korzystający z Platformy podaje dane niezbędne do wypełnienia i zawarcia umów koniecznych do realizacji finansowania Projektów za pośrednictwem Platformy.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Informacje te są między innymi niezbędne do identyfikacji użytkownika, weryfikacji spełnienia warunków rejestracji i uczestnictwa w finansowaniu Projektu, komunikacji z użytkownikiem, wykonania zobowiązań lub realizacji obowiązków ciążących na Crowder.Pro na podstawie obowiązujących przepisów prawa lub na podstawie wyroków, postanowień, decyzji i innych aktów urzędowych właściwych sądów, organów i instytucji, a także są przekazywane spółce realizującej Projekt w celu wypełnienia formalności i zawarcia umów niezbędnych do uczestnictwa w finansowaniu Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        W przypadku wyrażenia przez Użytkownika odrębnej (dobrowolnej, konkretnej, świadomej i jednoznacznej) zgody, dane będą przetwarzane w celach marketingowych związanych z działalnością Crowder.Pro.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        6. Rodzaje danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Administrator może przetwarzać następujące rodzaje i kategorie danych osobowych:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. dane kontaktowe i identyfikacyjne takie jak: imię i nazwisko, adres e-mail, numer telefonu, numer NIP, nazwa i adres głównego miejsca wykonywania działalności gospodarczej (w przypadku przedsiębiorców);
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        2. inne dane identyfikacyjne, takie jak: obywatelstwo, numer (PESEL) lub data urodzenia – w przypadku gdy nie nadano numeru PESEL, seria i numeru dokumentu stwierdzającego tożsamość osoby, adres zamieszkania.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        7. Okres przetwarzania danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Dane osobowe przetwarzane są w okresie koniecznym dla realizacji celów przetwarzania.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Po upływie powyższego okresu dane zostaną usunięte, chyba że ich dalsze przetwarzanie w określonym zakresie stanowi obowiązek wynikający z przepisów prawa, pozostaje koniecznie dla wywiązania się z zobowiązań umownych, dla zapewnienia bezpieczeństwa użytkowników, zwalczania oszustw i nadużyć lub też ustalenia i ochrony naszych praw lub dochodzenia roszczeń.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Co do zasady dane osobowe będą przechowywane:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        - zgodnie z maksymalnym okresem przedawnienia dla roszczeń wynikających z Umowy Uczestnictwa;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        - zgodnie z odpowiednimi przepisami dotyczącymi wygaśnięcia zobowiązań publicznoprawnych (w szczególności podatkowych);
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        - w innych przypadkach co do zasady nie dłużej niż przez dziesięć lat.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        8. Miejsce przechowywania danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Administrator przechowuje dane osobowe w państwach Europejskiego Obszaru Gospodarczego („EOG”).
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        9. Profilowanie danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Niektóre dane osobowe mogą być przetwarzane automatycznie, w szczególności dla celów analizy statystycznej lub prezentowania zindywidualizowanych treści marketingowych, bez wpływu na uprawnienia użytkownika.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        10. Udostępnianie danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Dane osobowe udostępniane są firmom, które świadczą usługi na rzecz Crowder.Pro, w przypadku gdy jest to konieczne do realizacji tych usług. W szczególności chodzi o usługi takie jak hosting i przechowanie danych, mailing, dostarczanie narzędzi komunikacji z użytkownikami, analiza danych i badania satysfakcji klientów oraz inne usługi konieczne lub wspierające prowadzenie działalności drogą elektroniczną. Dodatkowo w przypadku takiej konieczności: usługi księgowe, kadrowo – płacowe, usługi marketingowe, usługi IT, usługi finansowe, usługi prawne, usługi konsultingowe i inne usługi związane z prowadzeniem przedsiębiorstwa.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Każdy podmiot otrzymujący dane osobowe jest zobowiązany do zapewnienia najwyższej ochrony danych zgodnie z obowiązującymi przepisami prawa.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Spółki realizujące Projekty
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        W zakresie niezbędnym do przeprowadzenia finansowania Projektów Crowder.Pro udostępnia dane osobowe spółkom realizującym Projekty.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Organy publicznoprawne
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Dane osobowe mogą być przekazywane właściwym organom administracyjnym, instytucjom i innym uprawnionym podmiotom w kraju i za granicą jeżeli wymagają tego od nas obowiązujące przepisy prawa, jak również na ich żądanie w ramach przewidzianych prawem procedur.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Dane osobowe mogą być także przekazywane organom, instytucjom i osobom trzecim dla zapewnienia bezpieczeństwa użytkowników, zwalczania oszustw i nadużyć lub jeżeli jest to konieczne do ustalenia i ochrony naszych praw lub dochodzenia roszczeń.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        11. Zabezpieczenia ochrony danych osobowych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Administrator wdrożył odpowiednie zabezpieczenia organizacyjne, techniczne i fizyczne w celu ochrony przetwarzanych danych osobowych. Dane przechowywane są w środowisku, które Administrator uważa za bezpieczne, tym niemniej należy pamiętać, iż w przypadku każdego środowiska teoretycznie możliwe są incydenty bezpieczeństwa danych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Stosowane zabezpieczenia: dostęp do serwera za pomocą kluczy publiczno-prywatnych, dostęp do bazy danych z adresów IP znajdujących się na białej liście, komunikacja prowadzona za pomocą protokołów bezpiecznych.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        12. Prawa użytkownika związane z przetwarzaniem danych osobowych
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Bezpieczeństwo danych osobowych użytkowników jest dla nas najważniejsze. Przetwarzanie danych odbywa się z zachowaniem najwyższych standardów. Użytkownikom przysługują następujące uprawnienia:
                    </Typography>

                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo dostępu do danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Użytkownik ma prawo żądać informacji o przetwarzaniu jego danych osobowych, zakresie przetwarzania, celu przetwarzania i sposobach przetwarzania, podmiotach, którym są lub mogą być udostępnione dane oraz źródłach ich pozyskania.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Szczegółowe informacje można uzyskać za pośrednictwem wskazanego w Polityce Prywatności adresu e-mail lub w inny dowolny sposób kontaktując się z Crowder.Pro.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo do przenoszenia danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Użytkownik ma prawo otrzymania kopii danych osobowych, w powszechnie używanym, ustrukturyzowanym i nadającym się do odczytu maszynowego formacie, a także żądać przekazania tych danych innemu podmiotowi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo do poprawiania danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Użytkownik ma prawo zażądać od nas niezwłocznego poprawienia nieprawidłowych danych oraz - z uwzględnieniem celów przetwarzania - uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo do usunięcia danych
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Użytkownik ma prawo żądać niezwłocznego usunięcia dotyczących go danych osobowych, gdy:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. nie są już niezbędne do celów, w których zostały zebrane lub w inny sposób przetwarzane,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. użytkownik cofnął zgodę na przetwarzanie danych, a Crowder.Pro nie posiada innej ważnej podstawy do ich przetwarzania,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. użytkownik wniósł sprzeciw i nie występują nadrzędne prawnie uzasadnione podstawy przetwarzania,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. dane osobowe były przetwarzane niezgodnie z prawem,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. dane osobowe muszą zostać usunięte w celu wywiązania się z obowiązku prawnego.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo do ograniczenia przetwarzania
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Użytkownik ma prawo zażądać, ograniczenia przetwarzania danych osobowych:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        w przypadku gdy uważa, że jego dane są niepoprawne - do czasu aż sprawdzimy poprawność danych,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        w przypadku niezgodności przetwarzania z prawem, bez zgody na usunięcie danych,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        w przypadku ustania przyczyny przetwarzania, przy jednoczesnej konieczności dochodzenia roszczeń, ustalenia lub ochrony praw,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        w przypadku sprzeciwu odnośnie przetwarzania danych - do czasu zbadania czy mamy prawnie uzasadniony nadrzędny interes uzasadniający dalsze przewarzanie, pomimo sprzeciwu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo do cofnięcia zgody na przetwarzanie
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        W przypadku przetwarzania danych osobowych na podstawie zgody użytkownika, użytkownik ma prawo do cofnięcia tej zgody w dowolnym momencie. Cofnięcie zgody nie ma wpływu na zgodność z prawem już dokonanego przetwarzania danych osobowych na podstawie zgody.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} sx={{ textDecoration: "underline" }}>
                        Prawo do sprzeciwu
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Użytkownik ma prawo wnieść w dowolnym momencie sprzeciw wobec przetwarzania danych osobowych (w tym profilowania) do celów wynikających z prawnie uzasadnionych interesów administratora lub osoby trzeciej. Dalsze przetwarzanie danych jest możliwe tylko wówczas, gdy istnieją ważne prawnie uzasadnione nadrzędne podstawy do przetwarzania danych osobowych lub określone dane są one konieczne do dochodzenia roszczeń, ustalenia lub ochrony praw.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        13. Wykonywanie praw
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Przepisy nie determinują formy ani sposobu komunikacji w ramach ochrony danych osobowych. Dla ułatwienia Crowder.Pro utworzyło adres kontaktowy, za pośrednictwem którego można zgłaszać potrzeby związane z wykonywaniem swoich uprawnień: [--]
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        Jeśli w ocenie użytkownika jego dane osobowe były lub są przetwarzane w sposób nieprawidłowy przysługuje mu także skarga do organu nadzorczego (Prezes Urzędu Ochrony Danych Osobowych).
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        Niniejsza Polityka Prywatności może być zmieniana w drodze jednostronnej decyzji Crowder.Pro, zawsze jednak zgodnie z obowiązującym prawem, orzecznictwem lub wytycznymi wydanymi przez właściwe organy. Informacje o zmianie Polityki Prywatności będą podawane przed wejściem zmian w życie. Aktualna Polityka Prywatności dostępna będzie pod adresem strony internetowej Platformy Crowder.Pro.
                    </Typography>

                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        14. Polityka prywatności dla cookies i innych podobnych technologii
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Strona <Link to="https://www.crowder.pro/">https://www.crowder.pro/</Link> może używać cookies oraz innych podobnych technologii (dalej łącznie: „cookies”). Cookies to pliki tekstowe wysyłane przez stronę lub podmioty trzecie za pośrednictwem strony i przechowywane na urządzeniu użytkownika, a następnie odczytywane przez przeglądarkę.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Cookies są technologią powszechnie wykorzystywaną do przechowywania i przekazywania informacji umożliwiającą m.in. zapamiętywanie ustawień i preferencji użytkownika, gromadzenie statystyk i optymalizację stron internetowych, personalizowanie wyświetlanych treści oraz dopasowywanie reklam.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Niektóre cookies są niezbędne do zapewnienia bezpiecznej i wydajnej komunikacji / transmisji danych w sieci lub należytego działania podstawowych funkcji witryny. Inne rodzaje cookies pozwalają analizować sposób korzystania z witryny w celu poprawy wydajności oraz ulepszenia interakcji, doświadczenia i sposobu obsługi użytkownika. Możliwe jest także wykorzystanie cookies służących do dostosowania wyświetlanych reklam do potrzeb i preferencji użytkownika oraz udostępniania stron i treści za pośrednictwem zewnętrznych sieci społecznościowych i innych stron zewnętrznych, w tym dla celów reklamowych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Przetwarzane kategorie danych:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        rodzaj używanego urządzenia, unikatowy numer urządzenia, adres IP urządzenia, system operacyjny, rodzaj przeglądarki, informacje o sposobie korzystania z witryny i informacje diagnostyczne, historia wyszukiwania, podsumowanie sesji, informacje o lokalizacji oraz inne dane dostarczane automatycznie za pośrednictwem przeglądarki internetowej.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Cookies pozostają w pamięci urządzenia: (i) do czasu zamknięcie przeglądarki (cookies sesyjne) lub (ii) na stałe do czasu ich usunięcia albo upływu okresu wygaśnięcia (cookies stałe). Możliwe jest samodzielne usunięcie plików cookies z urządzenia poprzez ustawienia przeglądarki internetowej. Możliwe jest wyłączenie w ustawieniach przeglądarki możliwości zapisywania wszystkich plików cookies lub jedynie cookies pochodzących od osób trzecich. W przypadku wyłączenia możliwości zapisywania cookies nasza strona może nie działać prawidłowo lub też nie będziesz mógł skorzystać z niektórych funkcjonalności.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1}>
                        Dane osobowe związane z cookies są przetwarzane automatycznie, w szczególności dla celów dostosowania zawartości strony do preferencji użytkownika, optymalizacji procesu korzystania ze stron internetowych, analizy statystycznej lub prezentowania zindywidualizowanych treści marketingowych. Przetwarzanie danych w ten sposób nie wpływa na uprawnienia użytkownika.
                    </Typography>
                    <Typography fontSize={13} >
                        W pozostałym zakresie do przetwarzania danych w ramach cookies stosuje się odpowiednio postanowienia niniejszej Polityki Prywatności.
                    </Typography>
                </Box>
            </Paper>
        </Container >
    )
}

export default PrivacyCrowder