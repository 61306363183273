import { Container, Paper, Typography, Box } from '@mui/material';
import bgImage from "../../../assets/img/login-register.jpg";

const UserAgreement = () => {
    return (
        <Container
            sx={{
                display: "flex",
                justifyContent: "center",
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                WebkitBackgroundSize: "cover",
                backgroundAttachment: "fixed",
                width: "100%",
                minHeight: "100vh"
            }}
            style={{
                maxWidth: "none",
                padding: "0px"
            }}
        >
            <Paper elevation={0} sx={{ width: "90%", marginTop: "5%", }}>
                <Typography sx={{ display: "flex", justifyContent: "center", color: "#ffffff", background: "#00519b", fontSize: "32px", padding: "12px 20px" }} >
                    UMOWA NA PROWADZENIE KONTA
                </Typography>
                <Box sx={{ padding: "24px" }}>
                    <Typography fontSize={13} marginBottom={5}>
                        Regulamin platformy crowdlendingu (crowdfoundingu pożyczkowego) Crowder.Pro
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>

                        §1.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Postanowienia ogólne
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Niniejszy Regulamin określa zasady korzystania przez Użytkowników z platformy crowdlendingu (crowdfundingu pożyczkowego) zarządzanej przez Administratora.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Przed rozpoczęciem korzystania z Platformy, każdy Użytkownik zobowiązany jest zapoznać się z niniejszym Regulaminem.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Rozpoczęcie korzystania z Platformy jest równoznaczne z akceptacją postanowień Regulaminu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Udzielanie Pożyczek wiąże się z ryzykiem poniesienia straty. Decyzja dotycząca udzielenia Pożyczki powinna każdorazowo zostać podjęta przez Użytkownika samodzielnie, na podstawie własnej oceny sytuacji finansowej oraz oceny ryzyka związanego z udzieleniem Pożyczki.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        5. Regulamin nie określa:
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={8}>
                        a. elementów stosunku prawnego istniejącego między Administratorem oraz Właścicielem Projektu, który regulowany jest odrębną umową określającą prawa i obowiązki tych podmiotów,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b. elementów stosunku prawnego pomiędzy Użytkownikiem a Właścicielem Projektu, które mogą być regulowane odrębną umową pomiędzy tymi podmiotami,
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        6. Nazwa Platformy, logo, zasady działania Platformy, wszystkie jego elementy graficzne, interfejs, a także oprogramowanie, na którym się opiera, kod strony oraz bazy danych podlegają ochronie na podstawie przepisów ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz innych bezwzględnie obowiązujących przepisów prawa.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        §2.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Definicje
                    </Typography>
                    <Typography fontSize={13} marginBottom={2}>
                        Na potrzeby Regulaminu poniższe wyrażenia pisane wielką literą będą posiadać następujące znaczenie:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Administrator – Crowder Spółka z ograniczoną odpowiedzialnością z siedzibą w Białymstoku (15-540), przy ul. Żurawiej 71, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy w Białymstoku, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000855701, NIP: 9662142494, REGON: 386802855, posiadającą kapitał zakładowy w wysokości 205 000,00 zł, opłaconym w całości, będąca administratorem Platformy oraz administratorem danych osobowych Użytkowników;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Administrator Zabezpieczenia – podmiot powołany przez Pożyczkodawcę, na podstawie odrębnej umowy o ustanowieniu administratora zabezpieczenia zawartej pomiędzy danym Pożyczkodawcą i Administratorem Zabezpieczenia, do działania w charakterze administratora hipoteki na postawie art. 682 Ustawy o Księgach Wieczystych i Hipotece, w charakterze administratora zastawu na podstawie art. 4 ust. 1 Ustawy o Zastawie Rejestrowym i Rejestrze Zastawów lub do działania jako agent zabezpieczenia w odniesieniu do innego rodzaju zabezpieczenia;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Finansowanie - środki pieniężne, które Projektodawca zamierza pozyskać od osób trzecich w ramach realizacji Projektu promowanego na Platformie;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Kodeks cywilny – ustawa z dnia 23 kwietnia 1964 r. – Kodeksy cywilny;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. Konto – przydzielona danemu Użytkownikowi, identyfikowana za pomocą adresu e-mail, część Platformy, za pomocą której Użytkownik może dokonywać określonych działań w ramach Platformy;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        6. Pliki cookies – pliki zawierające dane, które mogą być wysłane z Platformy do przeglądarki internetowej Użytkownika, a następnie zapisane w jego przeglądarce. Użytkownik może samodzielnie skonfigurować przeglądarkę internetową na swoim komputerze w taki sposób, aby akceptowała ona otrzymywanie plików cookies, bądź je odrzucała;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        7. Polityka prywatności – polityka prywatności na Platformie stosowana przez Administratora;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        8. Pożyczka – określona ilość środków pieniężnych przenoszona na własności Pożyczkobiorcy przez Pożyczkodawcę na podstawie Umowy Pożyczki;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        9. Pożyczkobiorca – podmiot, który na podstawie Umowy Pożyczki zobowiązał się do przyjęcia Pożyczki na określonych w tej umowie warunkach, zabezpieczenia tej Pożyczki oraz do jej zwrotu wraz z należnymi odsetkami w terminie określonym w Umowie Pożyczki;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        10. Pożyczkodawca – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca zdolności prawnej, która udziela Projektowi finansowania w formie udzielenia Pożyczki;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        11. Platforma – strona internetowa https://www.crowder.pro/;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        12. Prawo Autorskie – ustawa z dnia 4 lutego 1994 o prawie autorskim i prawach pokrewnych;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        13. Prawo Bankowe – ustawa z dnia 29 sierpnia 1997 r. – Prawo bankowe;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        14. Projekt – akcja pozyskania kapitału dla przedsięwzięcia inwestycyjnego Właściciela Projektu, w drodze zaproszenia do składania ofert zawarcia umów pożyczki;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        15. Regulamin – niniejszy regulamin Platformy;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        16. Treści – wszelkiego rodzaju utwory zgodnie z definicją określoną w Prawie Autorskim, np. informacje tekstowe, graficzne, multimedialne itp.;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        17. Ustawa o Księgach Wieczystych i Hipotece – ustawa z dnia 6 lipca 1982 r. o księgach wieczystych i hipotece
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        18. Ustawy o obrocie instrumentami finansowymi – ustawa z dnia 29 lipca 2005 r. o obrocie instrumentami finansowymi;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        19. Ustawa o ofercie publicznej – ustawa z dnia 29 lipca 2005 r. o ofercie publicznej i warunkach wprowadzania instrumentów finansowych do zorganizowanego systemu obrotu oraz o spółkach publicznych;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        20. Ustawa o usługach płatniczych – ustawa z dnia 19 sierpnia 2011 r. o usługach płatniczych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        21. Ustawa o Zastawie Rejestrowym i Rejestrze Zastawów – ustawa z dni 6 grudnia 1996 r. o zastawie rejestrowym i rejestrze zastawów;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        22. Użytkownik – każda osoba korzystająca z Platformy.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        23. Właściciel Projektu – podmiot, który zamierza pozyskać Finansowanie w drodze realizacji Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        §3.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Warunki i zasady korzystania z Platformy
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Platforma jest dostępna dla każdego Użytkownika. Przeglądanie informacji zawartych na Platformie nie wymaga założenia Konta.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        2. W celu prawidłowego korzystania z Platformy niezbędne jest:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a) połączenie z siecią Internet,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b) posiadanie urządzeń pozwalających na korzystanie z zasobów sieci Internet oraz
                    </Typography>
                    <Typography fontSize={13} marginBottom={3} marginLeft={8}>
                        c) korzystanie z przeglądarki internetowej umożliwiającej wyświetlanie na ekranie urządzenia dokumentów hipertekstowych, powiązanych w sieci Internet przez sieciową usługę www.
                    </Typography>

                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Korzystanie z niektórych funkcjonalności Platformy przez Użytkownika może wymagać założenia Konta. Konto udostępniane jest przez Administratora nieodpłatnie.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Utworzenie Konta jest równoznaczne z wyrażeniem przez Użytkownika zgody na przekazywanie przez Administratora informacji o charakterze marketingowym, handlowym i informacyjnym dotyczącym Platformy. W szczególności Użytkownik posiadający Konto na Platformie może otrzymywać Newsletter od Administratora, zawierający informacje dotyczące działalności Platformy.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. Utworzenie Konta nie wiąże się ze świadczeniem żadnych odpłatnych lub bezpłatnych usług na rzecz Użytkownika.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        6. Użytkownikiem Konta może zostać osoba fizyczna, która ukończyła 18 lat i posiada pełną zdolność do czynności prawnych, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości prawnej, ale mogąca we własnym imieniu nabywać prawa i zaciągać zobowiązania.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        7. W przypadku osób prawnych i jednostek organizacyjnych, utworzyć Konto w ich imieniu oraz dokonywać wszelkich dalszych czynności w ramach Platformy może jedynie osoba, która jest umocowana do działania w tym zakresie w imieniu tych podmiotów.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        § 4.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Zasady funkcjonowania Platformy
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Platforma ma charakter platformy finansowania społecznościowego, na której Właściciele Projektu prezentują swoje Projekty, a Użytkownicy mają możliwość zapoznania się z nimi i przejścia na stronę internetową Właściciela Projektu dedykowaną realizacji Projektu, na której prezentowane są informacje dotyczące Właściciela Projektu, Projektu oraz zasad i warunków ewentualnego uczestnictwa w Projekcie przez Użytkowania.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Na Platformie prezentowane są informacje o ofertach udzielania Pożyczek.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        3. Administrator udostępnia Właścicielowi Projektu przestrzeń na Platformie w celu przedstawienia Użytkownikom przez Właściciela Projektu następujących informacji o Projekcie:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a. firma Właściciela Projektu,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b. krótki opis działalności Właściciela Projektu,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        c. kwota Finansowania zbierana przez Właściciela Projektu,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        d. forma zbierania finansowania,
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={8}>
                        e. okres trwania Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Na Platformie nie są rozpowszechniane ani udostępniane informacje dotyczące warunków i zasad pozyskania Finansowania w ramach realizacji Projektu przez Właściciela Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. Warunki i zasady pozyskania Finansowania w ramach realizacji Projektu przez Właściciela Projektu, w szczególności informacje oraz dokumenty związane z udzielaniem Pożyczek są udostępniane na stronie internetowej Właściciela Projektu, dedykowanej do realizacji Projektu. Jeżeli Pożyczka ma zostać zabezpieczona w określony sposób, np. poprzez hipotekę ustanowioną przez Projektodawcę na rzecz Administratora Zabezpieczeń, Pożyczkobiorca zobowiązany jest zamieścić na swojej stronie internetowej imię i nazwisko lub nazwę i kontakt do planowanego Administratora Zabezpieczenia.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        6. Platforma umożliwia Użytkownikowi zainteresowanemu danym Projektem przejście za pośrednictwem aktywnego linku do strony internetowej Właściciela Projekt, na której znajdują się szczegółowe informacje o danym Projekcie oraz o Właścicielu Projektu, a także szczegółowe warunki i zasady pozyskiwania Finansowania przez Właściciela Projektu w ramach realizacji Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        7. Wszelkie informacje, Treści lub innego rodzaju materiały znajdujące się na Platformie a dotyczące Projektu mają charakter wyłącznie promocyjny i reklamowy oraz nie stanowią oferty w rozumieniu przepisów Kodeksu Cywilnego, rekomendacji ani porady inwestycyjnej, podatkowej lub prawnej.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        8. Administrator nie jest odbiorcą oświadczenia woli Użytkownika, ani nie pośredniczy w zawieraniu umów lub dokonywaniu innych czynności prawnych pomiędzy Użytkownikiem a Właścicielem Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        9. Użytkownik, który składa oświadczenie o objęciu akcji lub udziałów Właściciela Projektu, w związku z Projektem promowanym na Platformie, nie staje się klientem Administratora w rozumieniu przepisów Ustawy o obrocie instrumentami finansowymi. Na rzecz Użytkownika nie jest świadczona przez Administratora jakakolwiek usługa maklerska w rozumieniu art. 69 Ustawy o obrocie instrumentami finansowymi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        10. Administrator nie przyjmuje wpłat tytułem Pożczyki.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        11. Klientem Administratora jest wyłącznie Właściciel Projektu, którego projekt promowany jest na Platformie, przy czym Administrator nie świadczy usługi, o której mowa w art. 72 Ustawy o obrocie instrumentami finansowymi, ani żadnej innej usług maklerskiej, na rzecz żadnego z Właścicieli Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        12. W celu uniknięcia wątpliwości, Administrator:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a. nie prowadzi działalności jako firma inwestycyjna w rozumieniu art. 3 pkt 33 Ustawy o obrocie instrumentami finansowymi, a w związku z tym nie wykonuje czynności wchodzących w zakres działalności maklerskiej, o której mowa w art. 69 ust. 2 i 4 Ustawy o obrocie instrumentami finansowymi,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b. nie organizuje ani nie prowadzi systemu obrotu instrumentami finansowymi w rozumieniu art. 3 pkt 9a ustawy o obrocie instrumentami finansowymi, w szczególności rynku regulowanego w rozumieniu art. 14 Ustawy o obrocie instrumentami finansowymi ani alternatywnego systemu obrotu instrumentami finansowymi w rozumieniu art. 3 pkt 2 Ustawy o obrocie instrumentami finansowymi,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        c. nie prowadzi działalności jako biuro usług płatniczych w rozumieniu art. 2 pkt 2a Ustawy o usługach płatniczych ani jako instytucja płatnicza w rozumieniu art. 2 pkt 11 Ustawy o usługach płatniczych, w szczególności nie świadczy usług płatniczych w rozumieniu art. 3 Ustawy o usługach płatniczych,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        d. nie pośredniczy w żaden sposób w Projekcie realizowanym przez Właściciela Projektu ani w zawieraniu jakichkolwiek umów lub dokonywaniu innych czynności prawnych pomiędzy Właścicielem Projektu oraz Użytkownikiem, w szczególności nie oferuje ani nie jest odbiorcą żadnej oferty w imieniu własnym lub Właściciela Projektu, nie przekazuje ani nie przyjmuje żadnego oświadczenia woli w imieniu własnym lub Właściciela Projektu, a także nie przekazuje ani nie przyjmuje żadnych środków pieniężnych w imieniu lub na rzecz Właściciela Projektu,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        e. nie prowadzi działalności bankowej określonej w Prawie Bankowym, w szczególności nie prowadzi działalności polegającej na gromadzeniu środków pieniężnych innych osób fizycznych, osób prawnych lub jednostek organizacyjnych niemających osobowości prawnej, w celu udzielania kredytów, pożyczek pieniężnych lub obciążania ryzykiem tych środków w inny sposób,
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={8}>
                        f. nie przeprowadza analiz inwestycyjnych Projektów w zakresie atrakcyjności inwestycyjnej dla Użytkowników ani nie gwarantuje osiągnięcia zysku przez Użytkownika z tytułu Pożyczki.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        § 5.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Odpowiedzialność Administratora
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Administrator nie może zagwarantować pełnej ciągłości działania Platformy oraz zapobiec przerwom w jego dostępności dla Użytkownika, które mogą być spowodowane czynnikami wewnętrznymi albo czynnikami zewnętrznymi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Administrator nie ponosi odpowiedzialności za przerwy w działaniu Platformy, zarówno za te spowodowane czynnikami zewnętrznymi, jak i za te spowodowane czynnikami wewnętrznymi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Administrator nie ponosi odpowiedzialności za ewentualne szkody poniesione przez Użytkownika spowodowane bezpośrednio lub pośrednio działaniem, niedziałaniem lub też błędnym działaniem Platformy. Administrator nie ponosi również odpowiedzialności za utracone przez Użytkownika korzyści spowodowane bezpośrednio lub pośrednio działaniem, niedziałaniem lub też błędnym działaniem Platformy.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Administrator nie ponosi odpowiedzialności za szkody spowodowane niezgodnym z Regulaminem lub niezgodnym z prawem wykorzystywaniem Platformy przez Użytkownika.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. Administrator nie ponosi odpowiedzialności za przerwy w działaniu Platformy wynikające z awarii spowodowanych wadliwym funkcjonowaniem sprzętu, oprogramowania lub łączy telekomunikacyjnych (systemów teleinformatycznych), których utrzymanie nie leży w gestii Administratora.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        6. Administrator nie ponosi odpowiedzialności za Treści dotyczące Właściciela Projektu, jak również za nienależyte wykonanie bądź niewykonanie przez Właściciela Projektu umowy lub innego zobowiązania wobec Użytkownika.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        7. Administrator nie ponosi odpowiedzialności za szkody powstałe w wyniku wzięcia przez Użytkownika w Projekcie realizowanym przez Właściciela Projektu, w szczególności za nieosiągnięcie przez Użytkownika zysków, korzyści lub założonych celów związanych z udzieleniem Pożyczki.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        8. Podmiotem wyłącznie odpowiedzialnym za przyjmowanie zapisów lub oświadczeń o udzieleniu Pożyczki i wpłat tytułem Pożyczki jest Właściciel Projektu. Wpłaty dokonywane są na rachunek bankowy Właściciela Projektu bezpośrednio lub za pośrednictwem podmiotu posiadającego zezwolenie na prowadzenie działalności w charakterze krajowej instytucji płatniczej wydane przez Komisję Nadzoru Finansowego i zarejestrowanego w rejestrze usług płatniczych, zgodnie z Ustawą o usługach płatniczych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        9. Administrator nie ponosi odpowiedzialności za funkcjonowanie strony internetowej Właściciela Projektu, poprzez którą udzielanie są Pożyczki Właścicielowi Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        10. Administrator nie ponosi odpowiedzialności za prawidłowość przebiegu płatności tytułem Pożyczki realizowane przez podmiot posiadający zezwolenie na prowadzenie działalności w charakterze krajowej instytucji płatniczej.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        11. Administrator nie odpowiada za działania osób trzecich obsługujących system informatyczny Platformy, jak również za szkody powstałe w wyniku uszkodzenia sprzętu komputerowego Użytkownika bądź jego zasobów danych, w trakcie lub w związku z korzystaniem z Platformy, w szczególności na skutek przedostania się do systemu informatycznego Użytkownika wirusów komputerowych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        12. Administrator nie odpowiada za niewykonanie lub nienależyte wykonanie przez Projektodawcę zobowiązania wynikającego z jakiejkolwiek umowy zawartej pomiędzy Właścicielem Projektu a Użytkownikiem.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        13. Użytkownik ponosi wyłączną odpowiedzialność za udostępnienie swojego Konta osobom trzecim.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5}>
                        § 6.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Odpowiedzialność Użytkownika
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Wszelkie działania podejmowane przez Użytkownika w ramach Portalu powinny być zgodne z dobrymi obyczajami oraz obowiązującymi przepisami prawa. Użytkownik nie może podejmować działań, które negatywnie wpływają na bezpieczeństwo funkcjonowania Platformy, renomę Administratora lub szkodzą Użytkownikom, Właścicielom Projektów lub Administratorowi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Użytkownik może posiadać i korzystać tylko z jednego Konta na Plarformie. Użytkownikowi nie wolno korzystać z Kont innych Użytkowników oraz udostępniać Konta innym osobom.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Zabrania się podawania danych niepełnych bądź nieprawdziwych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        4. W przypadku powzięcia przez Administratora uzasadnionych obaw co do bezpieczeństwa Konta, dotyczących w szczególności nieuprawnionego przejęcia Konta przez inną osobę, Administrator może:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a. uzależnić korzystanie z Portalu od potwierdzenia przez Użytkownika odpowiednimi dokumentami jego wiarygodności, w tym tożsamości,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b. czasowo ograniczyć dostęp do poszczególnych funkcjonalności w ramach Platformy,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        c. zawiesić na czas określony lub nieokreślony Konto.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        5. W ramach Platformy zabronione jest korzystanie przez Użytkowników z wirusów, botów, robaków bądź innych kodów komputerowych, plików czy programów w szczególności automatyzujących procesy skryptów i aplikacji bądź innych kodów, plików lub narzędzi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} >
                        § 7.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Ochrona danych osobowych i polityka prywatności
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Korzystanie przez Użytkowników z niektórych rodzajów funkcjonalności w Portalu wymaga podania danych osobowych i wyrażenia indywidualnej zgody na ich przetwarzanie przez Administratora. W tym przypadku podanie danych osobowych przez Użytkownika jest całkowicie dobrowolne, jednak niewyrażenie zgody na ich przetwarzanie może skutkować niemożliwością skorzystania z niektórych funkcjonalności oferowanych przez Administratora.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Użytkownikowi w każdej chwili przysługuje prawo wglądu do zgromadzonych jego danych osobowych oraz żądania ich poprawienia.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Administrator zastrzega, że Portal używa Plików cookies, które poprzez zapisywanie krótkich informacji tekstowych na komputerze Użytkownika umożliwiają jego identyfikację.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Zasady gromadzenia i przetwarzania danych osobowych oraz szczegóły dotyczące Plików cookies określone są w Polityce prywatności, która jest dostępna na stronie głównej Portalu, w sposób umożliwiający jej utrwalenie i zapisanie na dysku twardym komputera lub innym nośniku danych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        5. Użytkownika powinien zapoznać się z Polityką prywatności przed udostępnieniem lub przekazaniem Administratorowi jakichkolwiek danych osobowych.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} >
                        § 8.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Prawa własności intelektualnej
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Wszelkie Treści, lub innego rodzaju materiały znajdujące się na Platformie oraz niedotyczące danego Projektu są przedmiotem praw autorskich lub innych praw własności intelektualnej należących do Administratora lub wykorzystywanych przez Administratora na podstawie odrębnych umów.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Wszelkie Treści, grafiki lub innego rodzaju materiały znajdujące się na Platformie oraz dotyczące danego Projektu są przedmiotem praw autorskich lub innych praw własności intelektualnej należących do danego Właściciela Projektu lub wykorzystywanych przez danego Właściciela Projekt na podstawie odrębnych umów.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Użytkownik nie może wykorzystywać Treści lub innego rodzaju materiałów znajdujących się na Platformie (w całości lub w części) w celach komercyjnych bez uprzedniej zgody Administratora lub Właściciela Projektu (w zależności od tego, komu przysługują prawa własności intelektualnej do danej Treści lub innego rodzaju materiału).
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Użytkownik może wykorzystywać Treści lub innego rodzaju materiały znajdujące się na Platformie bez uprzedniej zgody Administratora lub danego Właściciela Projektu (w zależności od tego, komu przysługują prawa własności intelektualnej do danej treści, grafiki lub innego rodzaju materiały) tylko i wyłącznie w celach innych niż komercyjne, w szczególności w celach edukacyjnych, informacyjnych lub innych dopuszczalnych na podstawie obowiązujących przepisów prawa.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. Nazwy produktów związanych z przedmiotem działalności oraz usługami świadczonymi przez Administratora są nazwami handlowymi lub zarejestrowanymi znakami towarowymi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        6. Wszystkie użyte na Platformie znaki towarowe lub nazwy firmowe należą lub/i są zastrzeżone przez ich właścicieli i zostały użyte wyłącznie w celach informacyjnych. Dostępu Użytkownika do Platformy nie należy interpretować jako przyznania jakiejkolwiek licencji lub prawa do korzystania ze znaków występujących na Platformie bez uprzedniej pisemnej zgody Administratora lub danego Właściciela Projektu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        7. Administrator nie ponosi odpowiedzialności za treść informacji oraz plików przekazanych do prezentacji Projektu zamieszczonych na stronie internetowej Właściciela Projektu.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        § 9.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Rozpatrywanie reklamacji
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Użytkownikowi oraz Właścicielowi Projektu przysługuje prawo do złożenia reklamacji dotyczącej nieprawidłowego działania Platformy, na zasadach określonych w ust. 2 – 3.
                    </Typography>
                    <Typography fontSize={13} marginBottom={2} marginLeft={3}>
                        2. Reklamacja powinna zawierać co najmniej następujące informacje:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a) imię i nazwisko/nazwę Klienta,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b) adres Klienta,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        c) adres email Klienta,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        d) treść skargi, w tym dokładny opis nieprawidłowości będącej przedmiotem składanej skargi.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Reklamację można złożyć:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        1) w formie pisemnej:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={12}>
                        a) osobiście w siedzibie Zarządu Administratora,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={12}>
                        b) przesyłką pocztową, kurierską lub z wykorzystaniem innego posłańca – na adres Administratora;
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        2) w formie elektronicznej z wykorzystaniem środków komunikacji elektronicznej:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={12}>
                        a) pocztą elektroniczną.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        4. Administrator jest zobowiązany do rozpatrzenia reklamacji i udzielenia odpowiedzi na reklamację w terminie 30 (trzydziestu) dni od dnia otrzymania tej reklamacji. Termin rozpatrzenia reklamacji może być w uzasadnionych przypadkach wydłużony, a Administrator jest zobowiązany powiadomić o tym fakcie przed upływem terminu, o którym mowa w zdaniu poprzednim.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        § 10.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Likwidacja Konta
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Funkcjonowanie Konta w ramach Platformy ma charakter bezterminowy, z zastrzeżeniem postanowień zawartych poniżej.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Użytkownik może w każdym czasie zażądać usunięcia konta z Platformy.
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={3}>
                        3. Ze względów bezpieczeństwa Konto może usunąć wyłącznie Administrator. Aby usunąć swoje konto, Użytkownik musi skontaktować się z Administratorem drogą elektroniczną.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        § 11.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Kontakt
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Użytkownik może kontaktować się z Administratorem w sprawie funkcjonowania Konta na podstawie niniejszego Regulaminu w formie:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a. pisemnej na adres: Crowder Sp. z o.o., Żurawia 71, 15-540 Białystok,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b. elektronicznej (np. poprzez adres email wskazany na głównej stronie Portalu lub za pomocą formularza kontaktowego).
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Administrator może kontaktować się z Użytkownikiem w formie:
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        a. pisemnej - na wskazany przez Użytkownika adres korespondencyjny,
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={8}>
                        b. elektronicznej - na wskazany przez Użytkownika adres e-mail,
                    </Typography>
                    <Typography fontSize={13} marginBottom={5} marginLeft={8}>
                        c. telefonicznej - na wskazany przez Użytkownika numer telefonu.
                    </Typography>

                    <Typography fontSize={13} marginBottom={5}>
                        § 12.
                    </Typography>
                    <Typography fontSize={36} fontWeight={600} textAlign={"center"} marginBottom={2}>
                        Postanowienia końcowe
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        1. Niniejszy Regulamin obowiązuje od dnia jego publikacji na Platformie i jest dostępny na stronie głównej Platformy.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        2. Administrator ma prawo wprowadzania zmian w Regulaminie. O zmianie Regulaminu Administrator poinformuje poprzez zamieszczenie informacji na stronie Portalu. Zmiany wchodzą w życie w terminie wskazanym przez Administratora, nie krótszym jednak niż 7 dni od dnia zamieszczenie informacji na stronie Portalu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        3. Brak akceptacji nowego lub zmienionego Regulaminu przez Użytkownika jest równoznaczny z jego rezygnacją z korzystania z funkcjonalności Portalu.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        4. Wszelkie spory wynikłe pomiędzy Użytkownikiem a Administratorem mające związek z funkcjonowaniem Serwisu będą rozstrzygane przez sąd właściwy dla siedziby Administratora. W przypadku Użytkowników będących konsumentami w rozumieniu przepisów Kodeksu cywilnego, wszelkie spory wynikłe pomiędzy Użytkownikiem a Administratorem będą rozstrzygane przez właściwy sąd powszechny.
                    </Typography>
                    <Typography fontSize={13} marginBottom={1} marginLeft={3}>
                        5. Integralną część Regulaminu stanowi Polityka prywatności dostępna na stronie głównej Portalu.
                    </Typography>
                </Box>
            </Paper>
        </Container >
    )
}

export default UserAgreement;