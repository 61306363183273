import { Container, Alert, Typography, Button } from "@mui/material";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { forwardRef, useEffect } from 'react';

const ResetPasswordFinal = ({
    handleResetFinalSubmit,
    resetFinalMessage,
    setResetFinalChange,
    passwordResetFinalState,
    resetFinalError1Message,
    resetFinalError2Message,
    resetFinalError3Message,
}, ref) => {

    const {
        passwordResetFinalRef,
        passwordResetFinalConfRef
    } = ref

    return (
        <Container sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            px: "23%",
        }}
        >
            <ValidatorForm
                component="form"
                onSubmit={handleResetFinalSubmit}

            >
                <Typography component="h1" variant="h3" sx={{ marginBottom: "10px", textAlign: "center" }}>Wprowadź hasło</Typography>
                <TextValidator
                    required
                    id="standard-required-password"
                    label="Hasło"
                    margin="normal"
                    autoComplete='new-password'
                    inputRef={passwordResetFinalRef}
                    fullWidth
                    type='password'
                    onChange={setResetFinalChange}
                    value={passwordResetFinalState.passwordReset}
                    validators={['isEmpty', 'isPasswordLongEnough', 'isPasswordSpecial', 'required']}
                    errorMessages={['To pole jest wymagane', 'Użyj hasła składającego się z 8 lub więcej znaków', 'hasło musi mieć znaki specjalne oraz liczby']}
                />
                <TextValidator
                    style={{ marginBottom: "20px" }}
                    required
                    id="standard-required-passwordConfirm"
                    label="Potwierdź hasło"
                    placeholder='(Potwierdź hasło)'
                    margin="normal"
                    inputRef={passwordResetFinalConfRef}
                    fullWidth
                    type='password'
                    onChange={setResetFinalChange}
                    value={passwordResetFinalState.passwordResetConfirm}
                    validators={['isPasswordMatch', 'required']}
                    errorMessages={['Hasło się nie zgadza', 'To pole jest wymagane']}
                />
                {
                    resetFinalMessage && <Alert sx={{ mb: 4 }} severity="success"> {resetFinalMessage}</Alert>
                }
                {
                    resetFinalError1Message && <Alert sx={{ mb: 4 }} severity="error"> {resetFinalError1Message}</Alert>
                }
                {
                    resetFinalError2Message && <Alert sx={{ mb: 4 }} severity="error"> {resetFinalError2Message}</Alert>
                }
                {
                    resetFinalError3Message && <Alert sx={{ mb: 4 }} severity="error"> {resetFinalError3Message}</Alert>
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Zresetuj
                </Button>
            </ValidatorForm>
        </Container>
    )
}

export default forwardRef(ResetPasswordFinal);