import { Box, LinearProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2'

const LineWithdrawnChart = ({ statsLoader, labelsWithdrawn, withdrawnMonthly }) => {

    const dataMonthly = {
        labels: ['Marzec', 'Czerwiec', 'Wrzesień', 'Grudzień', 'Marzec'],
        datasets: [
            {
                data: withdrawnMonthly !== undefined ? withdrawnMonthly.map(withdrawn => { return withdrawn.amount }) : [0],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        xAxes: [{
            ticks: {
                callback: (v, i) => i + 1 <= labelsWithdrawn.length ? v : null
            }
        }]
        ,
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false
            },
            // tooltip: {
            //     enabled: false
            // },
            title: {
                display: false,
            },
        },
    };

    // useEffect(() => {
    //     console.log("loader: ", labelsWithdrawn)
    // }, [labelsWithdrawn])

    return (
        <Box position={'relative'} width={"100%"} height={"300px"}>
            {
                statsLoader
                    ?
                    <LinearProgress />
                    :
                    labelsWithdrawn.length > 0
                    &&
                    <Line data={dataMonthly} options={options} />
            }
        </Box>
    )
}

export default LineWithdrawnChart