import { Box, Button, Card, CardContent, Container, Divider, List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import uuid from "react-uuid";

const Documents = () => {
    const [documentsData, setDocumentsData] = useState([])

    const handleDownload = async (id, name) => {
        return axios.get(`/documents/project-documents/${id}/download/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        },
            {
                responseType: 'blob',
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                console.log("url: ", res.data.name)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${name}.pdf`)
                document.body.appendChild(link)
                link.click()
            })
            .catch((err) => {
                console.log("url: ", err)
                console.error(err)
            })
    }

    const translateStatus = (status) => {
        switch (status) {
            case 'failure':
                return 'NIESFINANSOWANY'
            case 'open':
                return 'TRWAJĄCY'
            case 'vindication':
                return 'W WINDYKACJI'
            case 'success':
                return 'SFINANSOWANY'
            case 'closed':
                return 'ZAMKNIĘTE'
            default:
                return 'TRWAJĄCY'
        }
    }
    useEffect(() => {
        axios('/documents/project-documents/', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }
        )
            .then(res => {
                setDocumentsData(res.data)
            })
            .catch(err => {
                console.log("error:", err)
            })
    }, [])

    return (
        <Container maxWidth={'md'} sx={{ marginTop: '100px' }} >
            {documentsData.map(el => {
                return (
                    <Card key={uuid()} sx={{ display: 'flex', marginBottom: 3 }} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid rgba(0, 0, 0, 0.34)', maxWidth: "220px", width: "100%" }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                    {el.project.name}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    Status
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary" component="div">
                                    {translateStatus(el.project.status)}
                                </Typography>
                            </CardContent>
                        </Box>
                        <List sx={{ width: "100%", }}>
                            {el.files.map(file => {
                                return (
                                    <>
                                        <ListItem key={uuid()} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography>{file.name}</Typography>
                                            <Button onClick={() => handleDownload(file.id, file.name)}>Pobierz</Button>
                                        </ListItem>
                                        <Divider />
                                    </>
                                )
                            })}
                        </List>
                    </Card>
                )
            })}
        </Container>
    )
}

export default Documents;