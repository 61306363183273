import { TextValidator, } from 'react-material-ui-form-validator';
import { forwardRef } from 'react';

const SecondStep = ({ setChange, userState }, ref) => {

    const { nameRef, phoneRef, streetRef, postCodeRef, cityRef } = ref

    return (
        <>
            <TextValidator
                margin="normal"
                required
                fullWidth
                id="name"
                label="Imię i nazwisko"
                name="name"
                autoComplete='new-name'
                autoFocus
                onChange={setChange}
                type="text"
                inputRef={nameRef}
                value={userState.user.name}
                validators={['isEmpty', 'isNameAndSurname', 'required']}
                errorMessages={['To pole jest wymagane', 'Imię i nazwisko się nie zgadza']}
            />
            <TextValidator
                required
                id="standard-required-phone"
                label="Numer telefonu"
                margin="normal"
                autoComplete='new-phone'
                inputRef={phoneRef}
                fullWidth
                type='phone'
                onChange={setChange}
                value={userState.user.phone}
                validators={['isEmpty', 'isPhone', 'required']}
                errorMessages={['To pole jest wymagane', 'Numer telefonu musi być podany w formacie: "+999999999". Maksymalnie 15 cyfr.', 'To pole jest wymagane']}
            />
            <TextValidator
                style={{ marginBottom: "20px" }}
                required
                id="standard-required-street"
                label="Ulica"
                placeholder=''
                margin="normal"
                inputRef={streetRef}
                fullWidth
                type='text'
                onChange={setChange}
                value={userState.user.street}
                validators={['isEmpty', 'isStreet', 'required']}
                errorMessages={['To pole jest wymagane', 'Ulica się nie zgadza', 'To pole jest wymagane']}
            />
            <TextValidator
                style={{ marginBottom: "20px" }}
                required
                id="standard-required-postCode"
                label="Kod pocztowy"
                placeholder='10-100'
                margin="normal"
                inputRef={postCodeRef}
                fullWidth
                type='text'
                onChange={setChange}
                value={userState.user.postcode}
                validators={['isEmpty', 'isPostCode', 'required']}
                errorMessages={['To pole jest wymagane', 'Kod pocztowy się nie zgadza', 'To pole jest wymagane']}
            />
            <TextValidator
                style={{ marginBottom: "20px" }}
                required
                id="standard-required-city"
                label="Miasto"
                placeholder='Miasto'
                margin="normal"
                inputRef={cityRef}
                fullWidth
                type='text'
                onChange={setChange}
                value={userState.user.city}
                validators={['isEmpty', 'required']}
                errorMessages={['To pole jest wymagane']}
            />
        </>
    )
}

export default forwardRef(SecondStep);