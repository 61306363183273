import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import uuid from 'react-uuid';

const TableRowsTransacts = ({ title, status, investedAmount, statusTransact }) => {

    return (
        <TableRow key={uuid()}>
            <TableCell>{title}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{statusTransact}</TableCell>
            <TableCell>{investedAmount} PLN</TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

export default TableRowsTransacts