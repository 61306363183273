import { Box, Typography } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { useNavigate, useSearchParams } from "react-router-dom"

const EmailVerify = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const access = searchParams.get('access')
    const code = searchParams.get('code')

    useEffect(() => {
        axios.post("/users/verify/email-confirm/", {
            "access": access,
            "code": code
        })
            .then(res => {
                setTimeout(() => {
                    navigate("/login")
                }, 2000)
            })
            .catch(err => {
                console.log("res: ", err)
            })

    }, [])

    return (
        <Box sx={{ width: "100%", marginTop: "200px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <RiCheckboxCircleFill size={120} color="#0bb6ad" />
            <Box sx={{ backgroundColor: "#0bb6ad", padding: "10px 20px", marginTop: "20px" }}>
                <Typography fontSize={24} color={'#fff'}>Email został zweryfikowany!</Typography>
            </Box>
        </Box>
    )
}

export default EmailVerify