import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import uuid from 'react-uuid';
import FilterAndSearch from '../FilterAndSearch';

const CustomTab = styled(Tab)(({ theme, selected }) => ({
    backgroundColor: selected ? '#fff' : 'inherit', // Background color for active tab
}));

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CrowderTabPanelInvestments = ({
    TabsEl,
    handleChange,
    tabValue,
    projectName,
    sortBy,
    product,
    handleFilterByPeriodByChange,
    handleProductChange,
    handleProjectNameChange,
    handleSortByChange,
    handleFilterSubmit,
    filterByPeriod,
}) => {

    return (
        <Box sx={{ marginTop: "100px", width: 'calc(100% - 60px)', padding: "20px 30px" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="fullWidth"
                    value={tabValue}
                    onChange={handleChange}
                >
                    {
                        TabsEl.map((tabel, i) => {
                            return (
                                <CustomTab key={uuid()} label={tabel.pl} {...a11yProps(i)} />
                            )
                        })
                    }
                </Tabs>
            </Box>
            <FilterAndSearch
                sortBy={sortBy}
                filterByPeriod={filterByPeriod}
                projectName={projectName}
                product={product}
                handleFilterByPeriodByChange={handleFilterByPeriodByChange}
                handleProductChange={handleProductChange}
                handleProjectNameChange={handleProjectNameChange}
                handleSortByChange={handleSortByChange}
                handleFilterSubmit={handleFilterSubmit}

            />
            {TabsEl.map((tabel, i) => {
                return (
                    <CustomTabPanel key={uuid()} value={tabValue} index={tabel.id}>
                        {tabel.Content}
                    </CustomTabPanel>
                )
            })}
        </Box>
    );
}

export default CrowderTabPanelInvestments;